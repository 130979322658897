import {ApiInstance} from "@/utilities/Api"
import * as apis from "./swagger-codegen"
import {
    AccountsApi,
    ActivityApi,
    AssignmentsApi,
    CategoriesApi,
    CollectionContentCampaignsApi,
    CollectionsApi,
    CollectionSourcesApi,
    ComponentsApi,
    DefaultApi,
    FoldersApi,
    GalleriesApi,
    InternalApi,
    LoginApi,
    ReportsApi,
    NotesApi, PaymentsApi,
    ProfileApi, SelectionsApi, SuggestionsApi, TopicsApi, UsersApi, PolicyApi
} from "./swagger-codegen";

export class Apis {
    private _api: ApiInstance;
    public readonly accounts: AccountsApi;
    public readonly profile: ProfileApi;
    public readonly activity: ActivityApi;
    public readonly assignments: AssignmentsApi;
    public readonly categories: CategoriesApi;
    public readonly collections: CollectionsApi;
    public readonly components: ComponentsApi;
    public readonly collectionContentCampaigns: CollectionContentCampaignsApi;
    public readonly collectionSources: CollectionSourcesApi;
    public readonly default: DefaultApi;
    public readonly internal: InternalApi;
    public readonly folders: FoldersApi;
    public readonly galleries: GalleriesApi;
    public readonly login: LoginApi;
    public readonly notes: NotesApi;
    public readonly payments: PaymentsApi;
    public readonly selections: SelectionsApi;
    public readonly suggestions: SuggestionsApi;
    public readonly topics: TopicsApi;
    public readonly user: UsersApi;
    public contentPolicy: PolicyApi;
    public reports: ReportsApi;
    constructor(api: ApiInstance) {
        this._api = api;
        this.accounts = new apis.AccountsApi({}, this._api.BASE_URL, this._api);
        this.profile = new apis.ProfileApi({}, this._api.BASE_URL, this._api);
        this.activity = new apis.ActivityApi({}, this._api.BASE_URL, this._api);
        this.assignments = new apis.AssignmentsApi({}, this._api.BASE_URL, this._api);
        this.categories = new apis.CategoriesApi({}, this._api.BASE_URL, this._api);
        this.collections = new apis.CollectionsApi({}, this._api.BASE_URL, this._api);
        this.components = new apis.ComponentsApi({}, this._api.BASE_URL, this._api);
        this.collectionContentCampaigns = new apis.CollectionContentCampaignsApi({}, this._api.BASE_URL, this._api);
        this.collectionSources = new apis.CollectionSourcesApi({}, this._api.BASE_URL, this._api);
        this.default = new apis.DefaultApi({}, this._api.BASE_URL, this._api);
        this.internal = new apis.InternalApi({}, this._api.BASE_URL, this._api);
        this.folders = new apis.FoldersApi({}, this._api.BASE_URL, this._api);
        this.galleries = new apis.GalleriesApi({}, this._api.BASE_URL, this._api);
        this.login = new apis.LoginApi({}, this._api.BASE_URL, this._api);
        this.notes = new apis.NotesApi({}, this._api.BASE_URL, this._api);
        this.payments = new apis.PaymentsApi({}, this._api.BASE_URL, this._api);
        this.profile = new apis.ProfileApi({}, this._api.BASE_URL, this._api);
        this.selections = new apis.SelectionsApi({}, this._api.BASE_URL, this._api);
        this.suggestions = new apis.SuggestionsApi({}, this._api.BASE_URL, this._api);
        this.topics = new apis.TopicsApi({}, this._api.BASE_URL, this._api);
        this.user = new apis.UsersApi({}, this._api.BASE_URL, this._api);
        this.contentPolicy = new apis.PolicyApi({}, this._api.BASE_URL, this._api)
        this.reports = new apis.ReportsApi({}, this._api.BASE_URL, this._api)
    }
}

<template>
    <form @submit.prevent.stop="invite" class="m-3">
        <h4>Sniply Brand Invite</h4>
        <hr>
        <label class="d-block">
            Email
            <input type="email" v-model="email" required class="form-control"/>
        </label>
        <label class="d-block">
            Brand
            <select-sniply-brand v-model="brand" ref="selectBrands" @loaded="() => {loaded=true}"></select-sniply-brand>
        </label>
        <button type="submit" class="btn-primary btn" :disabled="!loaded">Invite</button>
    </form>
</template>

<style>

</style>

<script>
    import SelectSniplyBrand from "@/components/Integrations/Sniply/SniplyBrandSelect";
    import {mapGetters} from 'vuex'
    export default {
        components: {SelectSniplyBrand},
        data() {
            return {
                brand: '',
                email: '',
                loaded: false
            }
        },
        computed: {
            ...mapGetters({
                apis : 'externalApis',
                currentAccountOverview: 'currentAccountOverview'
            })
        },
        methods: {
            invite() {
                this.$api.post(`/accounts/${this.currentAccountOverview.id}/sniply/brands/${this.brand}/invite`, {email: this.email, all_brands: this.$refs.selectBrands.$data.brands.map(x=>x.id)})
                    .then(() => alert('success!'))
                    .catch(() => alert('failed'))
            }
        }
    }
</script>

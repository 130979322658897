<template>
    <div class="pt-4 ps-4 pe-4 container">
        <div class="d-md-none">
<!--            <button type="button" class="btn btn-secondary">-->
<!--                Menu-->
<!--            </button>-->
            <select aria-label="Account" class="form-control form-select mb-2" :value="currentAccountOverview && currentAccountOverview.id" @change="setTeam(getTeam($event.target.value))" :disabled="locked">
                <option v-for="a in teams" :value="a.id">{{a.name}}</option>
            </select>
            <details class="full-width mb-4 border-default border-1 border rounded" open>
                <summary class="text-dark">
                    Account Configuration
                </summary>
                <div>
                    <div class="card full-width rounded-0 border-0" style="border-radius: 0">
                        <div class="card-header bg-primary text-white rounded-0">
                            <h3 class="card-title">Personal Settings</h3>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item sub-item">
                                <a href="#" @click.prevent="showing = 'user-profile'">
                                    Profile
                                </a>
                            </li>
                        </ul>
                    </div>
                    <team-sidebar-item
                        style="border-radius: 0"
                        class=" full-width rounded-0"
                        :team="currentAccountOverview"
                        @setEditor="showing = $event"
                        @deleteAccount="deleteAccount"
                        @setCurrentAccount="setTeam($event)"
                        :current-account="currentAccountOverview"
                    />
                </div>
            </details>
        </div>
        <div class="row">
            <div class="d-none d-md-block col-md-3">
                <div class="card ">
                    <div class="card-header bg-primary text-white">
                        <h3 class="card-title">Personal Settings</h3>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item sub-item">
                            <a href="#" @click.prevent="showing = 'user-profile'">
                                Profile
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                <div class="card mb-3" v-for="team in availableTeams" :key="'account-settings-'+team.id">
                    <team-sidebar-item
                        :team="team"
                        @setEditor="showing = $event"
                        @deleteAccount="deleteAccount"
                        @setCurrentAccountOverview="setTeam($event)"
                        :current-account="currentAccountOverview"
                    />

                </div>

                <button v-if="!hideSubscription"  @click="showing = 'new-account'" class="btn btn-outline-success full-width">
                    + Create New Account
                </button>

            </div>
            <div class="col-12 col-md-9">
                <div class="card mb-5">
                    <div class="card-body">
                        <user-profile v-if="showing === 'user-profile'"></user-profile>
                        <team-profile v-if="showing === 'team-profile'" :team="currentAccountOverview"></team-profile>

                        <div v-if="showing === 'integrations'">
                            <integrations :team="currentAccountOverview"></integrations>
                        </div>
                        <subscription-plan
                            v-if="showing === 'team-subscription-plan'"
                            :account="currentAccountOverview"
                        ></subscription-plan>
                        <payment-method
                            v-if="showing === 'team-payment-method'"
                            :account="currentAccountOverview"
                        />
                        <post-text-agents
                            v-if="showing === 'post-text-agents'"
                            :account="currentAccountOverview"
                        />
                        <content-quality-page
                            v-if="showing === 'content-quality-page'"
                            :account="currentAccountOverview"
                        />
                        <quality-checks :account="currentAccountOverview" v-if="showing === 'quality-checks'" ></quality-checks>
                        <new-account @accountCreated="handleNewAccount" v-if="showing === 'new-account'" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserProfile from "@/components/Settings/User/Profile"
import TeamProfile from "@/components/Settings/Team/Profile"
import SubscriptionPlan from "@/components/Settings/Team/SubscriptionPlan"
import PaymentMethod from "@/components/Settings/Team/PaymentMethod"
import PostTextAgents from "@/components/Settings/Team/PostTextAgents.vue";
import NewAccount from "@/components/Accounts/Create"
import TeamSidebarItem from "@/components/Accounts/SettingsSidebar"
import Integrations from '@/components/Settings/Team/Integrations'
import QualityChecks from '@/components/QualityChecks/QualityChecks'
import ContentQualityPage from "@/components/Settings/Team/ContentQualityPage.vue";

import {mapActions, mapGetters} from 'vuex';
import Dropdown from "../components/Controls/Dropdown";

export default {
    props : [],
    data(){
        return {
            openTeam : false,
            showing : 'user-profile',
            currentAccount : false,
            lastStoredCurrentAccount: false
        }
    },
    computed : {
        ...mapGetters({
            teams : 'allAccounts',
            user : 'currentUser',
            hideSubscription: 'hideSubscription',
            currentAccountOverview: 'currentAccountOverview',
        }),
        availableTeams(){
            console.log('* Settings.vue availableTeams this.teams', this.teams)
            return this.teams.filter(a => a.owner.includes(this.user.id) || a.role === 'administrator' || a.role === 'manager')
        }
    },
    components : {
        Dropdown,
        UserProfile,
        TeamProfile,
        SubscriptionPlan,
        PaymentMethod,
        PostTextAgents,
        NewAccount,
        TeamSidebarItem,
        Integrations,
        QualityChecks,
        ContentQualityPage,
    },
    watch : {
        currentAccountOverview(){
            if (this.lastStoredCurrentAccount && this.currentAccountOverview && this.currentAccount &&
                this.lastStoredCurrentAccount.id === this.currentAccountOverview.id &&
                this.currentAccount.id !== this.currentAccountOverview.id)
                return;
            this.currentAccount = this.currentAccountOverview
            this.lastStoredCurrentAccount = this.currentAccountOverview
        }
    },
    mounted(){
        this.currentAccount = this.currentAccountOverview
        console.log('* Settings.vue mounted this.currentAccount', this.currentAccount)

        if (this.$route.hash === '#subscription'){
            this.showing = 'team-subscription-plan'
        }

        if (this.$route.hash === '#team-profile' ){
            if (this.$route.query.account){
                let team = this.teams.find(t => t.id === this.$route.query.account);
                if (team){
                    this.openTeam = team;
                    this.currentAccount = team;
                }
            }
            this.showing = 'team-profile'
        }

        if (this.$route.hash === '#integrations' ){
            if (this.$route.query.account){
                let team = this.teams.find(t => t.id === this.$route.query.account);
                if (team){
                    this.openTeam = team;
                    this.currentAccount = team;
                }
            }
            this.showing = 'integrations'
        }
    },
    methods : {
        ...mapActions({
            changeAccount: 'changeAccount',
        }),
        async accountChange(value) {
            let account = this.teams.find(a => a.id === value);
            if (account) {
                await this.changeAccount({ overview: account })
            }
        },
        async handleNewAccount({account}){
            console.log(`* Settings handleNewAccount about to dispatch setCurrentAccount with:`, account)
            // await this.$store.dispatch('changeAccount', {overview: account});
            // console.log('* Settings.vue handleNewAccount about to setCurrentTopic')
            // this.$store.dispatch('setCurrentTopic', {topic: false});
            await this.$store.dispatch('setupNewAccount', {overview: account});
            this.$router.push('/welcome');
        },
        async setTeam(team){
            console.log('* setTeam team', team)
            // this.currentAccountOverview = team;
            await this.$store.dispatch('changeAccount', {overview: team});
            console.log('* setTeam this.currentAccountOverview', this.currentAccountOverview)
        },
        getTeam(id) {
            return this.teams.find(x => x.id === id)
        },
        teamIsOpen(team){
            return this.openTeam.id === team.id
        },
        deleteAccount(team){
            let x = confirm("Are you sure you want to delete this account? All users will lose access to all topics and suggestions");
            if ( ! x ) return;
            this.$store.dispatch('deleteAccount', {account : team});
            this.$api.delete('/accounts/'+team.id);
        },
        isPrimaryAccount(account){
            return this.user.default_account.includes(account.id);
        }
    }
}
</script>

<style scoped>
.card-header {
    padding: 0;
}
h3.card-title {
    padding: 15px;
}
.sub-item {
    font-size: 90%;
    padding-left: 20px;
}

.sub-item.active {
    border-left: 2px solid;
}
</style>

<script setup>
    import {defineProps, ref} from 'vue';
    import IconCheckbox from "@/components/Controls/IconCheckbox";
    defineProps({
        span: {type: Number},
        label: {type: String},
        expand: {type: Boolean, default: false},
        vSpan: {type: Number},
    })
    const printEnabled = ref(true)
</script>

<template>
    <div :class="`col col-12 col-lg-${span*6} col-xl-${span*3} mb-4 ${printEnabled ? '' : 'no-print'}`">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span>{{label}}</span>
                    <span class="font-size-18">
                        <icon-checkbox v-model="printEnabled" :icon-class="`fa ${printEnabled ? 'fa-eye' : 'fa-eye-slash'} font-size-18`" class="mb-0 print-checkbox"  title="Show on print/export"></icon-checkbox>
                    </span>
                </div>
            </div>
            <div class="card-body d-flex justify-content-center" :class="expand ? 'dynamic-height' : 'static-height'">
                <slot/>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .card-body {
        min-height: 300px;
    }
    .card-body.dynamic-height {
        height: fit-content;
    }
    .card-body.static-height {
        height: 300px;
        max-height: 300px;
    }
    @media print {
        .no-print {
            display: none;
        }
        .print-checkbox {
            display: none;
        }
        .card {
            break-inside: avoid;
        }
    }
</style>

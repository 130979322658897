<script setup>
import {defineProps} from 'vue';
import store from '../../store';
import PieChart from "@/components/Controls/charts/PieChart.vue";
import {usePieController} from "@/composables/usePieController";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const {labels, datasets, isLoading} = usePieController({
    key: 'by_collection',
    store,
    filters: () => props.filters,
    label: x => x.name,
    value: x => x.count,
    minPercent: 0.05,
    maxCount: 10,
});
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <PieChart
        v-else
        id="my-chart-id"
        :labels="labels"
        :datasets="datasets"
    />
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script setup lang="ts">
import {computed, defineProps, watch} from 'vue';
import {Bar} from "vue-chartjs";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {chartColors} from "@/Constants";
console.log('report:bar:setup')
const props = defineProps({
    datasets: {type: Array<any>},
    labels: {type: Array<any>},
    id: {type: String},
})
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const colors = Object.values(chartColors)
const chartData = computed(() => ({
    labels: props.labels,
    datasets: props.datasets.map((dataset: any, index) => (
        { ...dataset, backgroundColor: colors[index], stack: 'a' }
    ))
}))
const chartOptions = {
    responsive: true,
    options:{
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        }
    }
}
watch(chartData, x => console.log('report:bar:data', x))
</script>

<template>
    <Bar
        :id="id"
        :labels="chartOptions"
        :data="chartData"
    />
</template>

<style scoped>

</style>

<script setup>

import {computed} from "vue";
import {StringUtils} from "@/utilities/Strings";
import store from "@/store";

const print = () => window.print();
const accountName = computed(() => StringUtils.truncate(store.getters.currentAccountOverview.name, 30))

</script>

<template>
    <h1 class="h4 form-header">
        <span>Analytics<span class="d-none d-md-inline d-print-inline">: {{accountName}}</span></span>
        <div class="form-header-controls d-print-none">
            <button type="button" class="btn btn-primary btn-sm mb-2 flex-grow-1 flex-lg-grow-0" @click="print" id="reports-export-print-btn">
                <i class="fa fa-print"></i>
                Print/PDF
            </button>
        </div>
    </h1>
</template>

<style scoped>

</style>

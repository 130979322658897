<template>

    <div class="form">
        <ul class="errors" v-if="errorMessages && errorMessages.length">
            <li v-for="error in errorMessages" class="error" :key="error">{{ error }}</li>
        </ul>
        <template v-if="networkActivity">
            <progress-bar style="height: 30px; width: 100%;"/>
        </template>
        <div>
            <div>
                <input type="search" placeholder="Search Powerups" v-model="powerupSearchQuery" class="form-control form-control-sm mt-1 mb-2"/>
            </div>
            <div v-if="currentAccountOverview && collection">
                <power-up-container
                    v-model="amplifySettings.on"
                    :service="Amplify"
                    :team="currentAccountOverview"
                    :beforeAuth="beforeAuth"
                    label="Hootsuite Amplify"
                    description="Empower your team and reach your audience."
                    :learn-more-url="learnUrls.amplify"
                    iconClass="amplify-icon icon-dynamic"
                    :visible="showAmplify && powerupSearchMatches('amplify hootsuite employee advocacy engagement social') && powerUpEnabled('amplify')"
                >
                    <div v-if="accountHasAuthorizedAmplify && ! networkActivity">
                        <amplify-collection-settings v-model="amplifySettings" :all-topics="amplifyTopics"
                                                     :topics-loaded="amplifyTopicsLoaded"></amplify-collection-settings>
                    </div>
                </power-up-container>
                <div v-show="accountFeatures.everyone_social">
                    <everyone-social-accordion-section
                        v-show="collection && collection.id"
                        :beforeAuth="beforeAuth"
                        :collection="collection"
                        ref="everyoneSocial"
                        >
                    </everyone-social-accordion-section>
                </div>
                <power-up-container
                    :visible="powerupSearchMatches('sniply call to action cta conversion') && showSniply && powerUpEnabled('sniply')"
                    v-model="sniplyOn"
                    :service="Sniply"
                    :team="currentAccountOverview"
                    :beforeAuth="beforeAuth"
                    label="Sniply"
                    description="Drive conversions through content curation."
                    :learn-more-url="learnUrls.sniply"
                    iconClass="sniply-icon icon-dynamic"
                >
                    <div v-if="sniplyOn && ! networkActivity" class="mb-3">
                        <div class="mb-3">
                            <label>Sniply Campaign</label>
                            <spinner v-if="!sniplyCampaignsLoaded" class="dropdown"></spinner>
                            <sniply-campaign-select :campaigns="sniplyCampaigns" :campaign="sniplyCampaign" :cta="sniplyCta" @input="sniplyChanged" class="mt-2" />
                        </div>
                        <div v-if="accountFeatures.collectionParameterMappings">
                            <label>Query Parameter Mapping</label>
                            <spinner v-if="!sniplyMappingsLoaded" class="dropdown"></spinner>
                            <vue-select :options="sniplyMappings" v-model="sniplyMapping" label="name" placeholder="No mapping currently assigned" />
                        </div>
                    </div>
                </power-up-container>
                <lately-powerup
                    :visible="powerupSearchMatches('lately social generation') && powerUpEnabled('lately')"
                    :beforeAuth="beforeAuth" v-model="latelySettings" :dashboards="latelyDashboards"
                    :dashboards-loaded="latelyDashboardsLoaded">
                </lately-powerup>
                <power-up-container
                    :visible="powerupSearchMatches('constant contact email') && accountFeatures.constant_contact && powerUpEnabled('constant_contact')"
                    :value="true"
                    :service="service('constant_contact')"
                    :team="currentAccountOverview"
                    :can-turn-on-off="false"
                    :beforeAuth="beforeAuth"
                    label="Constant Contact"
                    description="Engage your audience with email digests."
                    :learn-more-url="learnUrls.constantContact"
                    iconClass="constant_contact-icon icon-dynamic"
                >
                    <constant-contact-collection-settings :collection="collection" :parent-modal="$refs.modal"
                                                          :needs-reset="resetSignal">

                    </constant-contact-collection-settings>
                </power-up-container>
                <hubspot-email-powerup
                    :visible="powerupSearchMatches('hubspot email digest') && powerUpEnabled('hubspot')"
                    :collection="collection"
                    :team="currentAccountOverview"
                    :show-rss-form="showHubspotEmailRssForm"
                    @showRssFormChange="showHubspotEmailRssFormChanged"
                    :beforeAuth="beforeAuth">
                </hubspot-email-powerup>
                <power-up-container
                    :visible="powerupSearchMatches('hubspot email icebreaker') && accountFeatures.icebreaker && powerUpEnabled('icebreaker')"
                    v-model="icebreakerOn"
                    :service="service('icebreaker')"
                    :team="currentAccountOverview"
                    :beforeAuth="beforeAuth"
                    label="Icebreaker"
                    description="Engage your contacts directly within HubSpot."
                    :learn-more-url="learnUrls.icebreaker"
                    iconClass="icebreaker-icon icon-dynamic"
                >
                </power-up-container>
                <mailchimp-email-powerup
                    :visible="powerupSearchMatches('mailchimp email digest') && powerUpEnabled('mailchimp')"
                    :collection="collection"
                    :team="currentAccountOverview"
                    :show-rss-form="showMailchimpEmailRssForm"
                    @showRssFormChange="showMailchimpEmailRssFormChanged"
                    ref="mailchimpPowerup"
                    :beforeAuth="beforeAuth">
                </mailchimp-email-powerup>
                <content-campaigns v-if="collection && collection.id && accountFeatures.contentTemplates"
                                   ref="contentCampaigns" :collection-id="collection.id"
                                   :visible="powerupSearchMatches('html email digest')">

                </content-campaigns>

                <div v-show="accountFeatures.openaiIntegration">
                    <CollectionPostTextSettingsPanel
                        ref="openai"
                        :collection-id="collection.id"
                    >
                    </CollectionPostTextSettingsPanel>
                </div>

                <collection-quality-checks v-if="collection && collection.id && features.proofpoint" ref="qualityChecks"
                                           :collection-id="collection.id"
                                           :visible="powerupSearchMatches('proof point proofpoint quality check')">
                </collection-quality-checks>
            </div>
        </div>
        <div class="alert alert-danger mt-2" v-if="networkError">
            <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: no user entered data-->
            <span v-html="networkError"></span>
        </div>
    </div>


</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import {VueSelect} from 'vue-select'

import ProgressBar from '@/utilities/ProgressBar'
import PowerUpContainer from '@/components/Controls/PowerUpContainer'
import {AmplifyConnection} from "@/services/Amplify";
import {SniplyConnection} from "@/services/Sniply";
import SniplyCampaignSelect from '@/components/Integrations/Sniply/SniplyCampaignSelect'
import Spinner from '@/components/Controls/Spinner'
import LatelyPowerup from "@/components/Collections/powerups/LatelyPowerup";
import {LatelyConnection} from "@/services/Lately";
import HubspotEmailPowerup from '@/components/Collections/powerups/HubspotEmailPowerup'
import MailchimpEmailPowerup from "@/components/Collections/powerups/MailchimpEmailPowerup";
import AmplifyCollectionSettings from "@/components/Integrations/Amplify/AmplifyCollectionSettings";
import ContentCampaigns from "@/components/Collections/powerups/ContentCampaigns";
import CollectionQualityChecks from "@/components/Collections/powerups/CollectionQualityChecks";
import {OAuthConnection} from "@/services/OAuth";
import ConstantContactCollectionSettings
    from "@/components/Integrations/ConstantContact/ConstantContactCollectionSettings";
import EveryoneSocialAccordionSection
    from "@/components/Integrations/EveryoneSocial/EveryoneSocialAccordionSection.vue";
import CollectionPostTextSettingsPanel from "@/components/Collections/powerups/CollectionPostTextSettingsPanel.vue";

export default {
    name: 'PowerUpsPanel',
    components: {
        ConstantContactCollectionSettings,
        CollectionQualityChecks,
        ContentCampaigns,
        AmplifyCollectionSettings,
        MailchimpEmailPowerup,
        LatelyPowerup,
        HubspotEmailPowerup,
        ProgressBar,
        PowerUpContainer,
        SniplyCampaignSelect,
        Spinner,
        VueSelect,
        EveryoneSocialAccordionSection,
        CollectionPostTextSettingsPanel,
    },
    props: {
        collection: Object,
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
            allCollections: 'collections',
            apis: 'externalApis',
            features: 'features',
            accountFeatures: 'accountFeatures',
            preferences: 'preferences',
            bannedIntegrationsForVendor: 'bannedIntegrationsForVendor',
            learnUrls: 'learnUrls'
        }),
        Amplify() {
            return new AmplifyConnection(this)
        },
        Sniply() {
            return new SniplyConnection(this)
        },
        Lately() {
            return new LatelyConnection(this)
        },
        showSniply() {
            return this.features.sniply;
        },
        showAmplify() {
            return this.features.amplify;
        },
        accountHasAuthorizedAmplify() {
            return this.currentAccountOverview && this.amplifyApiSettings.token;
        },
        amplifyApiSettings() {
            return this.currentAccountOverview && this.currentAccountOverview.id && this.apis.amplify[this.currentAccountOverview.id] ?
                this.apis.amplify[this.currentAccountOverview.id] : {};
        },
        collections() {
            return this.currentAccountOverview ? this.allCollections.filter(c => c.account.includes(this.currentAccountOverview.id)) : [];
        },
        convertedAmplifySettings() {
            console.log('convertedAmplifySettings', this.amplifySettings)
            return {
                on: Boolean(this.amplifySettings.on),
                topics: this.amplifySettings.on ? this.amplifySettings.topics : [],
                isPublished: this.amplifySettings.isPublished,
                isEditable: this.amplifySettings.isEditable,
                expiryDays: this.amplifySettings.expiryDays,
                summary: this.amplifySettings.summary && this.amplifySettings.summary !== 'no' ?
                    this.amplifySettings.summary : ''
            }
        },
        convertedSniplySettings() {
            return {
                sniply_cta_id: this.sniplyCta,
                sniply_campaign: this.sniplyCampaign,
                brand: this.sniplyBrand,
                param_mapping_id: this.sniplyMapping && this.sniplyMapping.id || null
            }
        },
        latelyDisabled() {
            return this.sniplyOn ?
                "Lately's post generator does not support Sniply links. \nPlease deactivate Sniply to use Lately" : "";
        },
        sniplyDisabled() {
            return this.latelySettings && this.latelySettings.on ?
                "Lately's post generator does not support Sniply links. \nPlease deactivate Lately to use Sniply" : "";
        },
        sniplyMapping:{
            get() {
                return this.sniplyMappings && this.sniplyMappings.find(m => m.id === this.sniplyMappingId, this) || null
            },
            set(mapping) {
                this.sniplyMappingId = mapping && mapping.id || null
            }
        },
    },
    data() {
        return {
            resetSignal: 0,
            powerupSearchQuery: '',
            powerupFilter: 'all',
            amplifySettings: {
                on: false,
                isPublished: false,
                isEditable: false,
                summary: '',
                expiryDays: null,
                publishType: false,
                topics: []
            },
            latelySettings: {
                on: false,
                dashboard: null,
                campaign: null
            },
            showHubspotEmailRssForm: false,
            showMailchimpEmailRssForm: false,
            errorMessages: [],
            latelyDashboards: [],
            latelyDashboardsLoaded: false,
            amplifyTopics: [],
            sniplyCampaign: false,
            sniplyCta: false,
            sniplyBrand: false,
            sniplyCampaigns: [],
            sniplyCampaignsLoaded: false,
            sniplyMappingId: null,
            sniplyMappings: [],
            sniplyMappingsLoaded: false,
            sniplyOn: false,
            constantContactOn: false,
            icebreakerOn: false,
            icebreakerWasOn: false,
            networkActivity: false,
            networkError: false,
            amplifyTopicsLoaded: false,
            isLoadingPowerUps: false,
        }
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            'loadCollectionPowerups',
        ]),
        loadPowerUps() {
            if (this.isLoadingPowerUps ||
                (typeof this.currentAccountOverview === 'undefined'
                    && typeof this.collection === 'undefined')) {
                return;
            }
            this.isLoadingPowerUps = true;

            this.fetchAmplifySettings();
            this.loadSniplyCampaignList().then(() => this.loadSniplySettings());
            this.loadSniplyMappings();
            this.loadIcbreakerSettings()
            this.loadAmplifyTopics();
            this.loadLatelyDashboards().then(() => this.fetchLatelySettings());
            if (this.$refs.everyoneSocial) {
                this.$refs.everyoneSocial.safeLoadEveryoneSocialIntegration();
            }
            if (this.$refs.openai) {
                this.$refs.openai.load();
            }
            this.loadCollectionPowerups();

            this.isLoadingPowerUps = false;
        },
        fetchAmplifySettings() {
            if (!(this.collection && this.collection.id && this.accountHasAuthorizedAmplify)) {
                return;
            }
            this.networkActivity++
            this.$api.get('/collections/' + this.collection.id + '/integrations/amplify/settings')
                .then(response => {
                    console.log('amplify response', response.data, response && response.data && response.data.settings)
                    if (response && response.data && response.data.settings) {
                        let settings = response.data.settings;
                        let topics = response.data.settings.topics;
                        console.log('pre-load amplify', response, settings)
                        this.amplifySettings = {
                            on: settings.on || false,
                            topics: topics,
                            isPublished: settings.isPublished || false,
                            isEditable: settings.isEditable || false,
                            summary: settings.summary,
                            expiryDays: settings.expiryDays,
                            publishType: settings.isPublished ? 'immediate' : settings.on ? 'draft' : false
                        }

                        console.log('load amplify', response, this.amplifySettings)
                    }
                    else {
                        this.resetAmplify()
                    }
                    this.networkActivity--;
                })
                .catch(e => {
                    console.error(e)
                    this.resetAmplify();
                    this.networkActivity--;
                });
        },
        resetAmplify() {
            this.amplifySettings = {
                on: false,
                topics: [],
                isPublished: false,
                isEditable: false,
                summary: 'summary,excerpt',
                expiryDays: null,
                publishType: false
            }
        },
        service(name) {
            return new OAuthConnection(name, this)
        },
        latelyLoaded({settings, options: {dashboards}}) {
            this.latelyDashboards = dashboards;
            this.latelySettings = settings;
        },
        fetchLatelySettings() {
            if (!(this.collection && this.collection.id && this.apis['lately'])) {
                return;
            }
            this.networkActivity++;
            return this.$api.get(`/collections/${this.collection.id}/lately-amsettings`)
                .then(response => {
                    this.networkActivity--;
                    if (response.data && response.data.settings) {
                        this.latelySettings = response.data.settings;
                    } else {
                        this.latelySettings = {
                            on: false,
                            dashboard: null,
                            campaign: null
                        };
                    }
                })
                .catch(() => {
                    this.networkActivity--;
                    this.latelySettings = {
                        on: false,
                        dashboard: null,
                        campaign: null
                    };
                });
        },
        async loadAmplifyTopics() {
            if (!this.accountHasAuthorizedAmplify) return Promise.resolve();
            let response = await this.Amplify.fetchTopics(this.currentAccountOverview);
            this.amplifyTopics = response.data.data.map(x => x.name);
            this.amplifyTopicsLoaded = true;
        },
        async loadLatelyDashboards() {
            if (!(this.collection && this.currentAccountOverview && this.apis['lately'] && this.apis['lately'][this.currentAccountOverview.id])) {
                return Promise.resolve();
            }
            this.latelyDashboardsLoaded = false;
            let dashboards = await this.Lately.fetchDashboards(this.currentAccountOverview).catch((error) => {
                    console.error('loadLatelyDashboards()', error);
                }
            );

            if (!dashboards || !dashboards.data) {
                return false;
            }

            this.latelyDashboards = this.$sort.alphabetize(dashboards.data.map(d => ({
                ...d,
                id: d._id,
                campaigns: this.$sort.alphabetize(d.campaigns.map(c => ({...c, id: c._id})))
            })));
            this.latelyDashboardsLoaded = true;
            return true;
        },
        async loadSniplySettings() {
            if (!(this.apis['sniply'] && this.collection && this.collection.id)) {
                return Promise.resolve();
            }
            this.networkActivity++;
            try {
                let response = await this.$api.get(`/collections/${this.collection.id}/sniply-settings`);
                if (response && response.data) {
                    let settings = response.data.settings;
                    this.sniplyOn = !!settings && !!(settings.sniply_campaign_id || settings.sniply_cta_id || settings.param_mapping_id);
                    this.sniplyCampaign = settings && settings.sniply_campaign_id;
                    this.sniplyCta = settings && settings.sniply_cta_id;
                    this.sniplyMappingId = settings && settings.param_mapping_id;
                    this.sniplyBrand = settings && settings.brand;
                } else {
                    this.sniplyOn = false;
                    this.sniplyCampaign = null;
                    this.sniplyMappingId = null;
                    this.sniplyBrand = null;
                }
            } finally {
                this.networkActivity--;
            }
        },
        async loadSniplyMappings() {
            if (!(this.apis['sniply'] && this.collection && this.collection.id)) {
                return Promise.resolve();
            }
            this.networkActivity++;
            try {
                let response = await this.$api.get(`/accounts/${this.currentAccountOverview.id}/sniply/param-mappings`);
                if (response && response.data && response.data.mappings) {
                    this.sniplyMappings = [...response.data.mappings, {id: null, name: 'No query parameter mappings'}];
                    this.sniplyMappingsLoaded = true;
                } else {
                    this.sniplyMappings = [];
                }
            } finally {
                this.networkActivity--;
            }
        },
        sniplyChanged({cta, campaign, brand}) {
            this.sniplyCampaign = campaign;
            this.sniplyCta = cta;
            this.sniplyBrand = brand;
        },
        async loadIntegrationSettings(name) {
            if (!(this.collection && this.collection.id && this.apis[name])) {
                return Promise.resolve();
            }
            this.networkActivity++;
            try {
                let response = await this.$api.get(`/collections/${this.collection.id}/integrations/${name}/settings`);
                if (response && response.data) {
                    return response.data.settings;
                }
            } finally {
                this.networkActivity--;
            }
        },
        async loadIcbreakerSettings() {
            this.loadIntegrationSettings('icebreaker').then(x => this.icebreakerOn = this.icebreakerWasOn = x && x.on)
        },
        async loadSniplyCampaignList(fetchNext) {
            if (!this.apis['sniply']) return Promise.resolve();
            if (fetchNext === undefined) this.sniplyCampaigns = [];

            let fetchFirst = (() => this.Sniply.fetchCampaigns(this.currentAccountOverview, this.sniplyCampaign));
            let campaigns = await (fetchNext || fetchFirst)();
            this.sniplyCampaigns = this.$sort.alphabetize([...this.sniplyCampaigns, ...campaigns.results]);

            if (campaigns.hasNext && this.sniplyCampaigns.length < campaigns.count)
                await this.loadSniplyCampaignList(campaigns.next);
            else {
                this.sniplyCampaignsLoaded = true;
                this.fetchSniplyBrands();
            }
        },
        fetchSniplyBrands() {
            let brands = [...new Set(this.sniplyCampaigns.map(x => x.brand))];
            Promise
                .all(brands.map(brand =>
                    this.$api.get(`/accounts/${this.currentAccountOverview.id}/sniply/brands/${brand}/`)))
                .then(results => {
                    results.forEach(result => {
                        let brand = result.data;
                        this.sniplyCampaigns
                            .filter(campaign => campaign.brand === brand.id)
                            .forEach(campaign => {
                                campaign.brandName = brand.name;
                                campaign.brandPhotoUrl = brand.photo_url;
                            })
                    })
                });
        },
        handleCollection() {
            if (this.collection && this.collection.id) {
                this.saveCollection()
            } else {
                this.createCollection();
            }
        },
        domain(url) {
            return url.replace(/https?:\/\//, '').replace(/\/.*/, '')
        },
        showHubspotEmailRssFormChanged(val) {
            this.showHubspotEmailRssForm = val;
        },
        showMailchimpEmailRssFormChanged(val) {
            this.showMailchimpEmailRssForm = val;
        },
        formatFeedDate(date) {
            return date ? moment.utc(date).local().fromNow().replace(/^[a-z]/, s => s.toUpperCase()) : ''
        },
        async saveCollection() {
            this.validate();
            if (this.errorMessages.length) return;

            this.networkActivity = "Saving collection...";

            await this.$store.dispatch('addCollection', {collection: this.collection});
            await Promise.all([
                this.$api.post(`/collections/${this.collection.id}/integrations/amplify/settings`,
                    this.convertedAmplifySettings),
                this.saveSniply(),
                this.saveLately(),
                this.saveIcebreaker(),
                this.saveQualityChecks(),
                this.$refs.everyoneSocial ? this.$refs.everyoneSocial.save() : Promise.resolve(),
                this.$refs.openai ? this.$refs.openai.save() : Promise.resolve(),
            ]);
            await this.$store.dispatch('loadAdvancedSettings');

            this.networkActivity = false;

            this.$emit("saved");

        },
        async saveQualityChecks(collection) {
            if (this.collection && this.collection.id && this.$refs.qualityChecks) {
                await this.$refs.qualityChecks.save(collection)
            }
        },
        async saveSniply() {
            if (!this.features.sniply) return Promise.resolve();
            if (this.sniplyOn && (this.sniplyCampaign || this.sniplyCta || this.sniplyMappingId))
                return this.$api.post('/collections/' + this.collection.id + '/sniply-settings',
                    this.convertedSniplySettings);
            if (this.userRemovedSniply())
                return this.$api.delete('/collections/' + this.collection.id + '/sniply-settings').catch(() => {
                });
            else return Promise.resolve(); // User either
        },
        async saveLately() {
            if (!this.features.lately) return Promise.resolve();
            if (this.latelySettings.on)
                return this.$api.post('/collections/' + this.collection.id + '/lately-settings', this.latelySettings);
            if (this.userRemovedSniply())
                return this.$api.delete('/collections/' + this.collection.id + '/lately-settings').catch(() => {
                });
            else return Promise.resolve(); // User either
        },
        async saveIcebreaker() {
            if (!this.accountFeatures.icebreaker) return Promise.resolve();
            if (this.icebreakerOn)
                return this.$api.post('/collections/' + this.collection.id + '/integrations/icebreaker/settings',
                    {on: this.icebreakerOn});
            if (!this.icebreakerOn && this.icebreakerWasOn)
                return this.$api.delete('/collections/' + this.collection.id + '/integrations/icebreaker/settings')
                    .catch(() => {
                    });
            else return Promise.resolve(); // User either
        },
        userRemovedSniply() {
            return !this.sniplyOn;
        },
        validate() {
            this.errorMessages = [];
            if (this.latelySettings && this.latelySettings.on) {
                if (!this.latelySettings.dashboard) this.errorMessages.push("Must select a Lately dashboard");
                if (!this.latelySettings.campaign) this.errorMessages.push("Must select a Lately campaign");
            }
            if (this.sniplyOn) {
                if(this.sniplyCampaign || this.sniplyCta || this.sniplyMappingId) {
                    return
                } else {
                    this.errorMessages.push("Must select a Sniply CTA or Query Parameter Mapping");
                }
            }

        },
        async saveChildrenForNewCollection(collection) {
            this.$api.post('/collections/' + collection.id + '/integrations/amplify/settings',
                this.convertedAmplifySettings);
            if (this.features.sniply && this.sniplyCampaigns)
                this.$api.post(`/collections/${collection.id}/sniply-settings`, this.convertedSniplySettings);
            if (this.features.lately && this.latelySettings.on)
                this.$api.post(`/collections/${collection.id}/lately-settings`, this.latelySettings);

            await this.saveQualityChecks(collection)
        },
        async beforeAuth() {
            this.networkError = false;
            // let collection;


            await this.$store.dispatch('setAfterOAuthData', {
                actions: [
                    ['showPowerUpsPanel', this.collection]
                ],
                url: window.location.pathname
            })
        },
        amplifyPublishChanged(...args) {
            console.log('amplifyPublishChanged', args)
            this.amplifySettings.isPublished = {...this.amplifySettings, isPublished: !this.amplifySettings.isPublished}
        },
        powerupSearchMatches(searchableTerms) {
            if (!this.powerupSearchQuery) return true;
            let searchTerms = this.powerupSearchQuery.split(' ');
            searchableTerms = searchableTerms.split(' ');
            return searchTerms.every(
                term => searchableTerms.some(word => word.toLowerCase().includes(term.toLowerCase())));
        },
        selectText(target) {
            target.setSelectionRange(0, target.value.length)
        },
        powerUpEnabled(name) {
            return !this.bannedIntegrationsForVendor.includes(name)
        },
    }
}
</script>

<style scoped lang="scss">
.advanced-toggle {
    font-size: var(--font-size-13);
    text-transform: uppercase;
}

.powerup-switch {
    margin-top: 0;
    margin-bottom: 0;
}

.advanced-options {
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
    max-height: 0;
}

.advanced-options.show {
    max-height: 1500px;
}

textarea.rss-area {
    height: 95px;
    font-size: .9em;
}

.rss-tab summary {
    padding: .25em .5em;
    font-size: 13px;
    background-color: var(--light);
    border: 1px solid var(--default);
    color: var(--dark);
}

.rss-links {
    columns: 4;
}

@media only screen and (max-width: 768px) {
    .rss-links {
        columns: 2;
    }
}

.auto-scroll {
    overflow: auto;
}

.height-500 {
    max-height: 300px;
}

.scroll-message-container {
    position: relative;
}

.scroll-message {
    position: absolute;
    bottom: 0;
    border-radius: 12px;
    background-color: var(--primary);
    color: white;
    padding: 3px 10px;
    font-size: .8em;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 200px;
    text-align: center;
}

.scroll-message:hover {
    opacity: 0;
    filter: alpha(opacity=0);
}

.errors {
    padding-left: 0;
    color: var(--danger);
    display: block;
}

.error {
    list-style: none;
    color: var(--danger);
    font-size: 0.9em;
}

.modal-footer .buttons {
    display: flex;
    justify-content: right;
}

.collections-modal .tab-content {
    max-height: calc(100vh - 270px);
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (min-height: 600px) {
    .collections-modal .tab-content {
        min-height: calc(400px);
    }

}


.legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: 16px;
    margin-bottom: 5px;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}
</style>

<template>
    <div class="suggestion-sidebar pt-2">
        <suggestion-manager
            v-if="accountFeatures.newCollectionUx"
        />
        <router-link class="btn btn-secondary full-width" v-if="accountFeatures.reports" to="/dashboard">
            <i class="fa fa-pie-chart text-white"></i>
            Dashboard
        </router-link>
        <template v-if="accountFeatures.newCollectionUx">
            <folder-navigator class="pt-2 pb-2"></folder-navigator>
        </template>
    </div>
</template>

<script>

import {mapGetters} from 'vuex';
import TopicSidebarFolderList from '@/components/Topics/SidebarList'
import FilterList from '@/components/FilteringAndSorting/Suggestions'
import CollectionsSidebarList from "@/components/Collections/SidebarList"
import SuggestionManager
    from "@/components/Suggestions/ManageSuggestion/SuggestionManager"
import FolderNavigator from "./ManageSuggestion/FolderNavigator";
import {RouterLink} from "vue-router";


export default {
    components: {
        FolderNavigator,
        RouterLink,
        TopicSidebarFolderList,
        FilterList,
        CollectionsSidebarList,
        SuggestionManager
    },
    data() {
        return {
            showFilterSidebar: true,
            isExpanded: false
        }
    },
    computed: {
        ...mapGetters({
            showingFavoriteSuggestions: 'showFavoriteSuggestions',
            suggestionViewerType: 'suggestionViewerType',
            accountFeatures: 'accountFeatures',
        }),
    },
    mounted() {

    },
    methods: {

    }
}
</script>
<style lang="scss">
// Do not scope these styles are they will not work.
.suggestion-sidebar.suggestion-sidebar {
    .sidebar-section {
        background: #fff;
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        padding: 0.75em;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-top: 0 !important;
    }

    .sidebar-link {
        text-transform: capitalize;
        font-weight: 100;
        font-size: 90%;
    }

    .folder-contents {
        margin-left: 10px;
    }

    .folder-contents.level-0 {
        margin-left: 0;
    }

    .suggestion-sidebar .pagination-container .page-item .page-link {
        padding-left: 5px;
        padding-right: 5px;
    }

    --ui-link: var(--primary-50);
    .ui-link {
        color: var(--ui-link);
        text-decoration: none;
    }

    --ui-link-hover: var(--primary-75);
    .ui-link:hover {
        color: var(--ui-link-hover);
    }

    .ui-link:hover .ui-link-text {
        text-decoration: underline;
    }
}
</style>

<template>
    <div class="dropdown" role="menuitem">
        <span  data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <slot name="toggle"/>
        </span>
        <div class="dropdown-menu mt-3" :class="`dropdown-menu-${align}`" role="menu" @click.stop >
            <slot name="dropdown" />
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
    export default {
        props: {
            align: {type: String, default: 'right'},
        }
    }
</script>

<template>
    <div>
        <div class="row">
            <div class="col-sm">
                <h4>Profile</h4>
            </div>
            <div class="col-sm-8">
                <div class="alert small" :class="'alert-'+activityMessageType" v-if="activityMessage">
                    {{activityMessage}}
                </div>
            </div>
        </div>

        <hr/>
        <div class="list-group no-borders">
            <div class="list-group-item">
                <div class="row">
                <div class="col-sm-2">
                    Name
                </div>
                <div class="col-sm-10">
                    <input class="form-control" v-model="name" @change="updateFieldForUser('name')" />
                </div>
                </div>
            </div>
            <div class="list-group-item">
                <div class="row">
                <div class="col-sm-2">
                    Email
                </div>
                <div class="col-sm-10">
                    <input class="form-control" v-model="email" @change="updateFieldForUser('email')" />
                </div>
                </div>
            </div>
        </div>

        <br/>

        <change-password @passwordChanged="updatePassword"></change-password>
        <hr>
        <div class="mb-3"  v-if="accountFeatures.languageInTopicSearch">
            <label>
                Default Language for Topics
                <default-culture-select></default-culture-select>
            </label>
        </div>
        <div>
            <label>
                <input type="checkbox" :checked="deviceShare" @change="$store.dispatch('setDeviceShare', {value: !deviceShare})"/>&nbsp;
                Use native share feature when accessing on this mobile device.
            </label>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ChangePassword from '@/components/Settings/ChangePassword'
import DefaultCultureSelect from "@/components/Settings/User/DefaultCultureSelector";

export default {
    props : [],
    data(){
        return {
            name : '',
            handle : '',
            email : '',
            activityMessage : '',
            activityMessageType : '',

        }
    },
    components : {
        DefaultCultureSelect,
        ChangePassword
    },
    computed : {
        ...mapGetters({
            currentUser : 'currentUser',
            currentAccountOverview : 'currentAccountOverview',
            deviceShare: 'deviceShare',
            accountFeatures: 'accountFeatures',
            features: 'features'
        }),
        browserSupportsShare() { return navigator && navigator.share; },
        mobileShareAllowed() { return this.browserSupportsShare && this.features.nativeShare && this.$mobileCheck.isMobile(); },
    },
    mounted(){
        this.name = this.currentUser.name;
        this.handle = this.currentUser.handle;
        this.email = this.currentUser.email;
    },
    methods : {
        displayMessage(text, type = 'info', duration = 3000){
            this.activityMessage = text;
            this.activityMessageType = type;
            setTimeout(() => {
                this.activityMessage = '';
                this.activityMessageType = ''
            }, duration)
        },
        updateFieldForUser(field){
            let originalValue = this.currentUser[field];

            this.displayMessage('Saving '+field+'...', 'primary', 3000)
            this.$store.dispatch('updateUserProfile', {key : field, value : this[field], user: this.currentUser, account : this.currentAccountOverview});

            let update = {}
            update[field] = this[field]

            this.$api.put("/"+field, update).catch( (e) => {
                if (e.response && e.response.data){
                    this.displayMessage(e.response.data.description, 'danger', 5000);
                    this[field] = originalValue
                    this.$store.dispatch('updateUserProfile', {key : field, value : originalValue, user: this.currentUser, account : this.currentAccountOverview});
                }
            });

        },
        updatePassword(){
            this.displayMessage('Saving password...', 'primary', 3000)
        }
    }
}
</script>

<style scoped>
.alert {
    margin-bottom: 0;
}
</style>

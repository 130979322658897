<template>
    <div class="pt-4 ps-4 pe-4 container">
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="card">
                    <div class="card-header bg-primary text-white">
                        <h3 class="card-title">Accounts</h3>
                    </div>
                    <div class="card-body">

                        <p class="text-muted small" v-if="isMovingTopics">
                            To move your topic to a folder in another account, select the account first.
                        </p>
                        <div class="list-group" v-if="currentAccountOverview">
                            <a 
                                href="#"
                                @click.prevent="setAccount(team)"
                                class="list-group-item small" 
                                v-for="team in accounts" 
                                :key="team.id"
                                :class="{'current' : currentAccountOverview.id === team.id}"
                            >
                                <i v-if="currentAccountOverview.id === team.id" class="fa fa-check-circle text-primary"/>&nbsp;
                                {{team.name}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-9">
                
                <div class="card">
                    <div class="card-body">
                        
                        <div class="alert alert-danger" v-if="networkError">
                            {{networkError}}
                        </div>

                        <div class="d-flex align-items-center" v-if="selectedTopics.length">
                            <h6 class="mb-2">{{selectedTopics.length}} topic<span v-if="selectedTopics.length > 1">s</span> selected</h6>
                            <span v-if="selectedTopics.length && ! isReviewer" class="ms-auto mb-2 topic-actions">
                                <button @click="editTopic" class="btn btn-link btn-sm my-0" v-if="selectedTopics.length == 1"><i class="fa fa-edit"></i> Edit Topic</button>
                                <button class="btn btn-link btn-sm my-0" v-if="selectedTopics.length == 1" @click="duplicateTopic"><i class="fa fa-copy"></i> Duplicate Topic</button>
                                <button class="btn btn-link btn-sm my-0" @click="isMovingTopics = !isMovingTopics">
                                    <span v-if="isMovingTopics">
                                        <i class="fa fa-times-circle"></i> Cancel Move
                                    </span>
                                    <span v-else>
                                        <i class="fa fa-arrow-right"></i> Move Topics
                                    </span>
                                </button>
                                <button class="btn btn-link btn-sm my-0" @click="deactivateSelectedTopics"><i class="fa fa-trash"></i> Deactivate Topics</button>
                                
                                
                            </span>
                        </div>
                        <h6 v-else>Topics in {{currentAccountOverview.name}}</h6>

                        <folder-display 
                            :folder="folder" 
                            v-for="folder in folders"
                            :key="'folder-browser-'+folder.id" 
                            @movingTopic="showFlatFoldersByAccount"
                            @topicAdded="addTopic"
                            @topicRemoved="removeTopic"
                            @disable="deactivate"
                            :isMovingTopics="isMovingTopics"
                            :is-root="true"
                            @createFolderWithNameInFolder="createFolderWithNameInFolder"
                            @deactivateTopic="deactivate"
                        />
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body">
                        <deactivated-topics 
                            ref="deactivatedTopics" 
                            :current-topic="currentTopic" 
                            :account="currentAccountOverview"
                            :account-topics="accountTopics"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import FolderDisplay from '@/components/Folders/TopicFolderDisplay'
import DeactivatedTopics from '@/components/Topics/DeactivatedTopics'
import Roles from '@/utilities/Roles'

export default {
    props : [],
    mixins : [Roles],    
    data(){
        return {
            newFolderName : '',
            showingFlatFolders : false,
            transitoryTopic : false,
            selectedTopics : [],
            isMovingTopics : false,
            networkError : ''
        }
    },
    components : {
        FolderDisplay, DeactivatedTopics
    },
    computed : {
        ...mapGetters({
            topics : 'topics',
            allFolders : 'folders',
            currentAccountOverview: 'currentAccountOverview',
            accounts : 'allAccounts',
            currentTopic : 'currentTopic'
        }),
        folders(){
            if ( ! this.currentAccountOverview ) return []
            return [this.allFolders.find(f => this.currentAccountOverview.root.includes(f.id))];
        },
        normalizedSelectedTopics(){
            return this.selectedTopics.map(topicId => {
                return this.topics.find(t => topicId.includes(t.id));
            })
        },
        accountTopics(){
            if (this.currentAccountOverview) {
                return this.currentAccountOverview.topics
            }
            return [];
        }
    },
    watch : {
        currentAccountOverview(){
            this.deactivatedTopics = [];
        }
    },
    mounted(){

        window.Events.$on('folderSelectedForTopics', ({folder}) => {
            let topics = this.selectedTopics.map(tId => {
                const topic = this.topics.find(t => tId.includes(t.id));
                return {...topic, folder : folder.id}
            })
            this.$store.dispatch('moveTopicsToFolder', {topics, folder})

            topics.forEach(topic => {
                let url = '/topics/'+topic.id+'/parent';
                if ( ! topic.account.includes(this.currentAccountOverview.id)){
                    url = '/topics/'+topic.id+'/account';
                }

                this.$api.put(url, {folder : folder.id, account : this.currentAccountOverview.id} )
                .catch(e => {
                    if (e.response && e.response.data){
                        this.networkError = e.response.data.description;
                    }
                })
            })

            this.isMovingTopics = false;
            this.selectedTopics = [];

            window.Events.$emit('selectedTopicsMovedToNewFolder');
        })


    },
    methods : {
        async setAccount(account){
            console.log(`* TopicsManager changeAccount with:`, account)
            await this.$store.dispatch('changeAccount', {overview: account});
        },
        editTopic(){
            let topic = this.topics.find(t => this.selectedTopics[0].includes(t.id));
            window.Events.$emit('showQueryBuilderModal', { topic });
        },
        async duplicateTopic(){
            const current = this.topics.find(t => this.selectedTopics[0].includes(t.id));
            let topic = {...current, id : false, name : current.name + ' - Copy' };
            window.Events.$emit('showQueryBuilderModal', { topic } );

            await this.resetCurrentAccountOverview();
        },
        async resetCurrentAccountOverview() {
            const newAccountOverviewResp = await this.$api.get(`/accounts/${this.currentAccountOverview.id}/overview`);
            await this.$store.dispatch('changeDataForAccount', { overview: newAccountOverviewResp.data});
        },
        async deactivateSelectedTopics(){

            this.selectedTopics.map(topicId => {
                return this.topics.find(t => topicId.includes(t.id));
            })
            .filter(t => t)
            .forEach( this.deactivate);

            await this.resetCurrentAccountOverview();

            this.selectedTopics = [];
        },
        deleteSelectedTopics(){
            let x = confirm("Are you sure you want to delete these topics? This cannot be undone");
            if ( ! x ) return;
            
            this.selectedTopics.forEach( async(topicId) => {

                let topic = this.topics.find(t => t.id === topicId)

                await this.$api.delete('/topics/'+topic.id).catch(async() => {
                    await this.$store.dispatch('addTopic', {topic : topic})
                });
                
                await this.$store.dispatch('deleteTopic', {topic : topic})
                if (topic === this.currentTopic){
                    if (this.accountTopics.length > 0){
                        console.log('* TopicsManager.vue deleteSelectedTopics about to setCurrentTopic this.accountTopics.length > 0')
                        this.$store.dispatch("setCurrentTopic", {topic: this.accountTopics[0]});
                    } else {
                        console.log('* TopicsManager.vue deleteSelectedTopics about to setCurrentTopic')
                        this.$store.dispatch("setCurrentTopic", {topic: false})
                    }
                }
            });

            this.selectedTopics = [];
        },
        async addTopic(topic){
            this.selectedTopics.push(topic.id);
            await this.resetCurrentAccountOverview();
        },
        removeTopic(topic){
            this.selectedTopics.splice(this.selectedTopics.indexOf(topic.id), 1)
        },
        foldersForAccount(account){
            if (!account.folders) return []
            return account.folders.map(fUrl => {
                return this.allFolders.find(f => fUrl.includes(f.id));
            }).filter(f => f)
        },
        topicsForFolder(folder){
            if ( ! folder.topics ) return []
            return folder.topics.map(tUrl => {
                return this.topics.find(t => tUrl.includes(t.id));
            })
        },
        showFlatFoldersByAccount(topic){
            this.transitoryTopic = topic;
            this.showingFlatFolders = true;
        },
        createFolderWithNameInFolder({name, folder}){
            
            let parentFolder = folder;

            this.$api.post('/accounts/'+this.currentAccountOverview.id+"/folders", {
                    name,
                    parent : parentFolder.id
                })
                .then(async(response) => {
                    let folder = {
                        ...response.data, 
                        topics : [], 
                        children : [],
                        account : this.currentAccountOverview.id
                    };

                    await this.$store.dispatch('addFolder', {folder});
                    this.$store.dispatch('addFolderToFolder', {
                            folder : folder,
                            parent: parentFolder,
                            account : this.currentAccountOverview
                    });

                }).catch(e => {
                    if (e.response && e.response.data){
                        this.networkError = e.response.data.description;
                    }
                });
        },
        deactivate(topic){
            this.$api.delete("/active-topics/"+topic.id)
            .then(async() => {
                await this.$store.dispatch('deleteTopic', {topic});
                this.$refs.deactivatedTopics.push(topic);
            })
            .catch(e => {
                if (e.response && e.response.data){
                    this.networkError = e.response.data.description;
                }
            });
            

        }
    }
}
</script>

<style scoped>
.selected-topics-list {
    font-size: var(--font-size-13);
}

.selected-topic-list-item {
    margin-bottom: 4px;
}

.deactivated-topics .list-group-item:hover {
    background: #f7fdff;
}

.topic-actions .btn-sm {
    font-size: var(--font-size-13);
}

/* .card {
    box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
} */

</style>

<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {defineProps, computed} from 'vue'
import PieChart from "@/components/Controls/charts/PieChart.vue";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const labels = computed(() => data.value.map(x => x.name));
const datasets = computed(() => [{data: data.value.map(x => x.count)}]);

const {data, load, listen, isLoading} = useReportData('by_country', store, () => props.filters);
listen();
load().then(() => console.log('report:by_collection:data', data.value.map(x => x.count || 0)));
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <PieChart
        v-else
        id="my-chart-id"
        :labels="labels"
        :datasets="datasets"
    />
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<template>
    <div class="signup-payment-details full-width">
        <div class="alert alert-danger" v-if="networkError">
            {{networkError}}
        </div>
        <credit-card-entry
                            ref="creditCardEntry"
                            :account="account"
                           :showButton="false"
                           @cardAdded="cardAdded"
                           @savingCard="networkActivity = true"
                           @cardError="handleError"
                           @focus="networkError = false"
        />

    </div>
</template>
<style lang="scss">
// Do not scope styles because input::placeholder is inside <password-field />
.signup-with-trial {
    .form-group {
        display: block;
        margin-bottom: 1.25em;
    }

    .form-group input:focus::placeholder {
        color: #fff !important;
    }

    .form-group input::placeholder {
        font-weight: var(--font-weight-light);
        color: var(--steel-50) !important;
        font-style: italic;
        transition: color 500ms;
    }
}
</style>
<script>
import Base from "@/components/Auth/V2/Base";
import CreditCardEntry from "@/components/Payments/CreditCardEntry";
import {mapGetters} from "vuex";

export default {
    name: 'PaymentDetails',
    mixins: [Base],
    components: {
        CreditCardEntry
    },
    data(){
        return {
            currentCard : false,
            noCard : false,
            networkActivity: false,
            networkError : false
        }
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
        }),
    },
    watch: {
        email() {
            this.errorMessage = '';
        },
        // currentAccountOverview(){
        //     this.fetchCard()
        //     this.currentCard = false;
        // }
    },
    mounted() {
    },
    methods: {
        cardAdded({card}){
            this.currentCard = card
            this.noCard = false
            this.networkActivity = false;
            this.networkError = false
        },
        fetchCard(){
            // this.networkActivity = true;
            // this.$api.get(`/accounts/${this.currentAccountOverview.id}/payment-method`)
            //     .then(response => {
            //         this.cardAdded({card : response.data})
            //     })
            //     .catch(error => {
            //         this.handleError({error})
            //     })
        },
        removeCurrentCard(){
            // this.networkActivity = true
            // this.$api.delete(`/accounts/${this.account.id}/payment-method`).then(() => {
            //     this.currentCard = false
            //     this.noCard = true;
            //     this.networkActivity = false;
            // })
        },
        handleError({error}){
            this.networkActivity = false;
            if(error.response){
                switch(error.response.status){
                    case 404:
                        this.noCard = true;
                        break
                }
            } else {
                this.networkError = "We cannot process your card at this time :("
            }

            this.currentCard = false
        },
        saveCard() {
            return this.$refs.creditCardEntry.saveCard();
        }
    }
}
</script>

<template>
    <modal ref="modal">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{header}} Gallery</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="purchasing || building">
                        <div class="plan-container mb-3">
                            <h4>Gallery
                                <br/>
                                <small>Single Site</small>
                            </h4>
                            <i class="fa fa-image plan-icon"></i>
                            <h3 class="cost">$75/mo</h3>
                        </div>
                        <payment-method :account="currentAccountOverview"></payment-method>
                    </div>
                    <div v-else>
                        <h5 class="moment">The moment you've been waiting for has arrived!</h5>
                        <h6 class="check-download">Check your downloads for your very own gallery.</h6>
                        <div class="party-container">
                            <img class="dogs" src="@/assets/images/celebration.png" />
                            <div class="fireworks-container">
                                <!-- Fireworks adapted from this codepen: https://codepen.io/yshlin/pen/ylDEk/ -->
                                <div class="pyro">
                                    <div class="before"></div>
                                    <div class="after"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" v-if="purchasing || building" class="btn btn-secondary float-end">Cancel</button>
                    <button type="button" v-if="purchasing || building" class="btn btn-primary float-end" @click="purchase" :disabled="loading">{{buttonText}}</button>
                    <button type="button" v-if="!purchasing && !building" class="btn btn-secondary float-end">Back to Preview</button>
                    <button type="button" v-if="!purchasing && !building" class="btn btn-secondary float-end">Back to Edit</button>
                    <button type="button" v-if="!purchasing && !building" class="btn btn-primary float-end" @click="purchase">Back to Galleries</button>
                </div>
            </div>
    </modal>
</template>

<style scoped>
    .plan-container {
        border: 1px solid #888;
        width: 150px;
        margin: 5px auto;
        text-align: center;
        padding: 1.2rem 0;
        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2),
        0px 2px 6px 3px rgba(0,0,0,0.1);
    }
    h5.moment { color: var(--bs-primary); }
    h6.check-download { color: var(--secondary); }
    .party-container {
        position: relative;
    }
    .fireworks-container{width:100%; height: 350px; position: absolute; top:-15px; }
    img.dogs { width: 100%; height: 350px}
    @media screen and (min-width: 964px)
    {
        .plan-container {
            width: 190px;
        }
    }

    @media screen and (max-width: 767px)
    {
        .plan-container {
            width: 100%;
        }
    }

    .plan-container.current {
        background: var(--water-100);
        border-color: var(--primary-50);
        box-shadow: none;
    }


    .plan-container h4 {
        color : var(--steel-33);
        font-size: var(--font-size-13);
        text-transform: uppercase;
    }

    .plan-container h4 small {
        text-transform: none;
        display: block;
        margin-top: 5px;
    }

    .plan-container .cost {
        font-weight: var(--font-weight-semibold);
        margin-bottom: 1.5rem;
    }

    .plan-icon {
        color: #496FF5;
        font-size: 2.7rem;
        margin: 1.7rem 0;
    }
</style>

<script>
    import * as $ from 'jquery';
    import PaymentMethod from '@/components/Settings/Team/PaymentMethod'
    import {mapGetters} from 'vuex';
    export default {
        props: ['open'],
        components: { PaymentMethod },
        data() {
            return {
                buttonText: 'Purchase',
                header: 'Purchase',
                loading: false,
                purchasing: true,
                building: false
            }
        },
        computed: {
            ...mapGetters({
                currentAccountOverview : 'currentAccountOverview'
            })
        },
        watch: {
            open() {
                if (this.open) {
                    this.show();
                }
            }
        },
        methods: {
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            purchase() {
                this.loading = true;
                this.buttonText = 'Building...';
                this.header = 'Building';
                this.building = true;
                this.purchasing = false;
                setTimeout(() => {
                    this.loading = true;
                    download('fake-gallery.txt');
                    this.header = 'Downloaded';
                    this.building = false;
                }, 2000)
            }
        }
    }
    function download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
</script>

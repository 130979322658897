<template>
    <form class="form" ref="form" @submit.prevent="submit($event)">
        <filters-form
            v-if="hasContentId && filters"
            v-model="filters"
            :type="hasContentType"
            :has-content-id="hasContentId"
            :tags="tags"
            :hasSuggestions="{type:hasContentType, id: hasContentId}"
        >

        </filters-form>
        <!--<input type="submit" v-if="type !== 'rss' && id && !(feedSuggestions && feedSuggestions.length)" class="btn btn-primary full-width" value="Add Source" :disabled="sourcesBusy || validating">-->
        <div class="text-danger">
            {{ sourceErrorMessage }}
            <div v-for="m in validationMessages" :key="m">{{m}}</div>
        </div>
        <filter-usages :filter-view-id="value" ref="usages" :currentUsage="currentUsage">

        </filter-usages>
        <spinner v-if="!filters"></spinner>
    </form>
</template>

<script>
import FiltersForm from "@/components/FilteringAndSorting/FiltersFormV2";
import HasFilters from '@/components/Collections/HasFilters';
import {mapGetters} from 'vuex'
import Spinner from '@/components/Controls/Spinner'
import {Filters} from "@/models/Filters";
import PublisherList from "@/components/FilteringAndSorting/PublisherList";
import {formatTag} from "../../services/entity-tags";
import FilterUsages from "../FilteringAndSorting/FilterUsages";
import {unique} from "../../utilities/Arrays";
import * as toastr from "toastr";

export default {
    mixins: [HasFilters, PublisherList],
    components: {FilterUsages, FiltersForm, Spinner},
    props: {
        value: {type: String, default: null},
        hasContentType: {type: String, default: null},
        hasContentId: {type: String, default: null},
        currentUsage: {type: String, default: null},
    },
    computed: {
        ...mapGetters({
            features: 'features',
            currentAccountOverview: 'currentAccountOverview'
        }),

    },
    data() {
        return {
            valueChanging: false,
            sourceErrorMessage: '',
            validating: false,
            publishers: [],
            tags: [],
            validationMessages: [],
            filters: null,
            filterViewId: false,
            toastOptions:  {
                preventDuplicates: true,
                timeOut: 0,
                extendedTimeOut: 0,
                clickClose: true,
                closeOnHover: false,
            }

        }
    },
    watch: {
        value() {
            console.log('FilterViewEdit.value changed', this.value)
            this.loadFilterViewData()
        },
        filterViewId() {
            this.$emit('input', this.filterViewId)
        },
        hasContentId() {
            this.loadLastFilterSettingsIfNew()
        }
    },
    mounted() {
        this.loadLastFilterSettingsIfNew();
        this.loadFilterViewData();
    },
    methods: {
        async loadLastFilterSettingsIfNew() {
            if (!this.value) { // if user is creating a new filter view
                let filters = await this.fetchLastFilterSettings(this.hasContentType, this.hasContentId);
                filters.id = null; // avoid overwriting prior saved filters
                filters.name = ''; // avoid defaulting a name for a new filter view
                this.filters = filters;
                this.filterViewId = null;
            }

        },
        async loadFilterViewData() {
            if (this.value) {
                let filterView = await this.$api.get(`/filter-views/${this.value}/`)
                    .then(resp => Filters.fromApi(resp.data));
                console.log('FilterViewEdit.load.filterView', filterView)
                this.filterViewId = this.value;
                this.filters = filterView;
            } else {
                await this.loadLastFilterSettingsIfNew()
            }
        },
        typeChange(val) {
            this.type = val;
        },
        validate(data) {
            this.validationMessages = this.$refs.usages.validate(data);
            if (this.validationMessages.length) {
                toastr.warning("Some filters you are trying to save are invalid for one of the locations this filter view is used", 'Invalid Filters in Filter View', this.toastOptions);
            }
        },
        setError(error) {
            this.sourceErrorMessage = error;
        },
        resetForm() {
            this.resetErrors();
            this.id = '';
        },
        resetErrors() {
            this.sourceErrorMessage = '';
            this.validationMessages = [];
        },
        setTopicCollectionId(id) {
            this.id = id;
        },
        async submit(event) {
            console.log('filter submit', event)
            let action = event.submitter.value;
            console.log('FilterViewEdit.save', this.filterViewId)
            let data = Filters.fromUi(this.filters).toApi();

            this.validate(data);

            if (this.validationMessages.length > 0) {
                return;
            }

            console.log('saving...', this.filterViewId, action)
            if (this.filterViewId && action === 'save') {
                await this.$api.put(`/filter-views/${this.filterViewId}/`, data);
                this.$emit('saved', this.filterViewId)
            } else {
                delete data.id;
                let response = await this.$api.post(`/accounts/${this.currentAccountOverview.id}/filter-views/`, data);
                this.filterViewId = response.data.id;
                console.log('saved filter view', this.filterViewId, data)
                this.$emit('saved', this.filterViewId)
            }
            return this.filterViewId;
        },
        // async save() {
        //     if (this.$refs.form.checkValidity())
        //         return await this.submit(false);
        // },
        // async saveNew() {
        //     if (this.$refs.form.checkValidity())
        //         return await this.submit(false);
        // }
    }
}
</script>

<template>
    <div class="powerups-simplified">
        <div v-for="powerup in powerups"
             :key="powerup.id"
             :title="powerup.title"
             class="powerups-simplified-powerup d-inline-block"
             :class="{'inactive': !powerup.active}"
             :aria-hidden="{'true': !powerup.active}"
        >
            <i class="powerups-simplified-powerup-icon " :class="iconClass(powerup)"></i>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.powerups-simplified {
    min-height: 2em;
}
.powerups-simplified-powerup {
    margin-right: .5em;
    margin-bottom: .5em
}
.powerups-simplified-powerup:last-child {
    margin-right: 0;
}
.powerups-simplified-powerup-icon {
    --sizing: 1em;
    width: var(--sizing);
    height: var(--sizing);
}
.powerups-simplified-powerup.inactive .powerups-simplified-powerup-icon  {
    // If we want to do this, we'd have to do a slash through it as some powerup
    // icons are already grayscale.
    //display: none;
    filter: grayscale(100%) opacity(0.25);
}
.powerups-simplified-powerup-icon.fa {
    bottom: 0.2em;
    position: relative;
}
</style>

<script>
import {mapGetters} from 'vuex';
import {getIconClass} from "../../../services/Icons";

export default {
    name: "PowerUpsPanelSimplified",
    components: {
    },
    props: {
    },
    data() {
        return {
            powerups: [],
        }
    },
    computed: {
        ...mapGetters({
            collection: 'currentCollection',
            collectionPowerups: 'collectionPowerups',
        })
    },
    watch: {
        collectionPowerups() {
            this.loadPowerUps();
        },
    },
    methods: {
        async loadPowerUps() {
            console.log('* PowerUpsPanelSimplified.vue loadPowerUps')
            // ToDo: This errors when you first login and go to a collection.

            const results = this.collectionPowerups;

            if (!this.collectionPowerups || !Array.isArray(this.collectionPowerups)) return;

            this.powerups = results
                ? this.$sort
                    .alphabetize(results.filter(p => p.isAuthenticated), 'title')
                    .sort((x, y) => (x.active === y.active)? 0 : x.active? -1 : 1)
                : [];
        },
        iconClass(powerup) {
            return getIconClass(powerup.name)
        }
    },
    mounted() {
        this.loadPowerUps();
    },
}
</script>

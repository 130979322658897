<template>
    <div>
        <fieldset class="row">
            <legend>
                General
            </legend>
            <label class="col col-6 label-calm">
                Favor Source over Page
                <select v-model="favorSourceOverPage" class="form-select">
                    <option>never</option>
                    <option>always</option>
                    <option>authoritative</option>
                </select>
            </label>
            <label class="col col-6 label-calm">
                Force Hardcoded Value
                <input type="text" v-model="hardcodedValue"  class="form-control">
            </label>
        </fieldset>
        <fieldset class="row">
            <legend>
                Page Element
            </legend>
            <label class="col col-6 label-calm">Selector
                <input type="text" v-model="selector"  class="form-control" list="parsing-config-selectors">
            </label>
            <label class="col col-6 label-calm">Attribute
                <input type="text" v-model="attribute"  class="form-control" list="parsing-config-attribute">
                (Blank to use element contents)
            </label>
        </fieldset>
        <fieldset class="row">
            <legend>
                Clipping
            </legend>
            <label class="col col-4 form-check-label form-check-inline">
                <input type="checkbox" v-model="fairUse" class="form-check-input">
                Enforce Fair Use
            </label>
        </fieldset>
        <fieldset v-if="isShowImageCheckbox" class="row">
            <label class="col col-4 form-check-label form-check-inline">
                <input type="checkbox" v-model="stripURLQueryParams" class="form-check-input">
                Strip URL Query Parameters
            </label>
        </fieldset>
    </div>
</template>

<script>
export default {
    name: "ParsingConfigField",
    props: {
        isShowImageCheckbox: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            selector: '',
            attribute: '',
            hardcodedValue: '',
            favorSourceOverPage: 'never',
            fairUse: false,
            stripURLQueryParams: false,
        }
    },
    watch: {
        selector() {
            this.$emit('input', this.$data)
        },
        attribute() {
            this.$emit('input', this.$data)
        },
        hardcodedValue() {
            this.$emit('input', this.$data)
        },
        favorSourceOverPage() {
            this.$emit('input', this.$data)
        },
        fairUse() {
            this.$emit('input', this.$data)
        },
        stripURLQueryParams() {
            this.$emit('input', this.$data)
        },
    }

}
</script>

<style scoped>
    legend {
        font-size: var(--font-size-16);
        border-bottom: 1px solid var(--default);
        padding: 0;
        margin: 1rem 0 .5rem 1rem;
    }
    fieldset {
        margin-bottom: 1rem;
        padding-right: 3rem;
    }
</style>

<template>
    <a 
        class="dropdown-item"
        href="#" @click.prevent="changeAccount"
        :disabled="isLoading"
        :class="{'disabled' : isLoading, 'is-primary' : currentAccountOverview && account.id === currentAccountOverview.id}"
    >
        <span class="text-primary primary-indicator">
            <i class="fa fa-check"></i>    
        </span> 
        
        <span class="me-5 account-name">
            {{account.name}}
        </span>

        <span class=" small ms-auto" v-if="isTeamAccount">
            <i class="fa fa-users" ></i>
        </span>

        <span v-if="isLoading">
            ...
        </span>
    </a>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props : ['account', 'isLoading'],
    computed : {
        ...mapGetters({
            currentAccountOverview : 'currentAccountOverview',
            subscriptions : 'subscriptions'
        }),
        subscription(){
            if (this.account)
                return this.subscriptions.find(s => s.account_id === this.account.id);
        },
        isTeamAccount(){
            if (this.subscription)
                return this.subscription && this.subscription.plan_name.includes("Team");
        },
    },
    methods : {
        async changeAccount(){
            console.log('* AccountMenuItemLink changeAccount about to dispatch changeAccount with', this.account)
            await this.$store.dispatch('changeAccount', {overview: this.account});
        }
    }
}
</script>

<style scoped>
    .primary-indicator {
        display: none;
        padding: 0 0.23rem 0 0.47rem;
        font-size: 66%;
    }

    .primary-indicator i {
        position: relative;
        top: 19%;
    }

    .is-primary .primary-indicator {
        display: inline;
    }
    .dropdown-item.is-primary {
        padding-left: 0;
    }
    .account-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<template>
    <div>
        <button class="btn btn-link folder-title" v-if="! isRoot" @click.prevent.stop="open = ! open">
            <i class="fa small me-1" :class="{'fa-chevron-down' : open, 'fa-chevron-right' : ! open}"/>
            {{ folder.name }}
        </button>

        <paged-collections
            :class="{'collections' : ! isRoot}"
            v-if="open"
            :collections="collections"
            :isCheckable="isCheckable"
            v-model="checkedCollections"
            :search="search"
            @containsCurrentCollection="open = true"
        />
        <template v-if="open">
            <by-folder
                v-for="child in children"
                class="folder-view"
                :isCheckable="isCheckable"
                v-model="checkedCollections"
                :folder="child"
                :search="search"
                :key="'recursive-folder-'+child.id"
            />
        </template>
    </div>
</template>

<script>

import ByFolder from './ByFolder'
import PagedCollections from './PagedCollectionInFolder'
import {mapGetters} from 'vuex';

export default {
    components: {
        ByFolder, PagedCollections
    },
    data() {
        return {
            open: false,
            checkedCollections: this.value
        }
    },
    name: "ByFolder",
    props: ['folder', 'isRoot', 'isCheckable', 'value', 'search'],
    computed: {
        ...mapGetters({
            folders: 'folders',
            collection: 'currentCollection',
            collections: 'collections',
            currentAccountOverview: 'currentAccountOverview',
        }),
        children() {
            //console.log('* ByFolder.vue this.folders', this.folders)
            if (!this.folder) return [];
            return this.folders.filter(f => f.parents.find(p => p && p.includes(this.folder.id)));
        },
        collections() {
            if (!this.folder) return [];
            if (!this.currentAccountOverview?.collections) return [];
            return this.currentAccountOverview?.collections.filter(c => c.folder && c.folder.includes(this.folder.id));
        },
        containsCurrentCollection() {
            return this.collections.indexOf(this.collection) > -1;
        }
    },
    watch: {
        checkedCollections() {
            this.$emit('input', this.checkedCollections);
        },
        value() {
            this.checkedCollections = this.value;
        },
        currentAccountOverview() {
            console.log('* ByFolder.vue currentAccountOverview changed, adding collection works but deleting a collection and returning to page does not pick up change')
        }
    },
    mounted() {
        this.open = this.isRoot
        if (this.containsCurrentCollection) {
            this.open = true;
            this.$emit('containsCurrentCollection');
        }
    }
}
</script>

<style scoped>
.collections {
    padding-left: 1rem;
}

.folder-view .folder-view {
    padding-left: 1rem;
}
</style>

import _default from "chart.js/dist/core/core.interaction";
import index = _default.modes.index;

export function useOtherGrouping({minPercent, maxCount}: {minPercent: number, maxCount: number}) {
    function summarizer({labels, data}: {labels: () => string[], data: () => number[]}) {
        const sum = values => values.reduce((sum, item) => sum + item, 0);
        const percentOfWhole = (value: number) => value / sum(data());
        const showValue = (value, index) => index < maxCount && percentOfWhole(data()[index]) > minPercent;
        const isOther = (value, index) => !showValue(value, index);
        const others = values => values.filter(isOther);
        const primary = values => values.filter(showValue)
        const construct = (primary, others, summarizeOthers) => others.length ? [...primary, summarizeOthers(others)] : primary
        return  {
            getLabels: () => construct(primary(labels()), others(labels()),o => `+${o.length} Others`),
            getData: () => construct(primary(data()), others(data()),o => sum(o)),
        };
    }

    return summarizer;
}

<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {ref, defineProps, computed, onMounted} from 'vue'
import DashboardTable from "@/components/Controls/charts/DashboardTable.vue";
import {StringUtils} from "@/utilities/Strings";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const rows = computed(() => data.value.sort((a, b) => b.count - a.count).slice(0, 10).map(x => [StringUtils.truncate(x.domain, 30), StringUtils.truncate(x.title, 75), x.count]));

const {data, load, listen, isLoading} = useReportData('top_articles', store, () => props.filters);

listen();
load().then(() => console.log('report:by_collection:data', data.value.map(x => x.count || 0)));
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <dashboard-table v-else :rows="rows" :labels="['Domain', 'Title', 'Count']"></dashboard-table>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script setup lang="ts">
import {defineProps} from 'vue';
const props = defineProps({
    values: Array<any>
})
const formatNumber = (value: number, isInteger: boolean|null = null) => {
    let postfix = '';
    if (value >= 1000) {
        value = value / 1000;
        postfix = 'k';
    }
    if (isInteger) {
        return value.toFixed()
    } else if (isInteger === false) {
        return value.toFixed(1);
    } else {
        const formatted = value.toFixed(1);
        return postfix ? formatted : formatted.replace(/\.0+$/g, '');
    }
}
</script>

<template>
    <div class="d-flex flex-column gap-4 justify-content-center">
        <div v-for="value in props.values" :key="value.label" class="number-container">
            <div>{{value.label}}</div>
            <div>
                <span class="big-number">{{formatNumber(value.value)}}</span>
                <span class="unit text-muted">{{value.unit}}</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.big-number {
    font-size: 4rem;
}
.unit {
    font-size: 1.3rem;
}
.number-container {
    text-align: center;
}
</style>

<template>
    <div class="row">
        <div class="col col-8">
            <div class="d-flex flex-wrap">
                <fieldset class="card">
                    <legend class="card-header">Title</legend>
                    <ParsingConfigField class="card-body" v-model="title"></ParsingConfigField>
                </fieldset>
                <fieldset class="card">
                    <legend class="card-header">Summary</legend>
                    <ParsingConfigField class="card-body" v-model="summary"></ParsingConfigField>
                </fieldset>
                <fieldset class="card">
                    <legend class="card-header">Date</legend>
                    <ParsingConfigField class="card-body" v-model="date"></ParsingConfigField>
                </fieldset>
                <fieldset class="card">
                    <legend class="card-header">Image</legend>
                    <ParsingConfigField class="card-body" v-model="image" :isShowImageCheckbox="true"></ParsingConfigField>
                </fieldset>
            </div>
        </div>
        <div class="col col-4">
            <output class="code-snippet p-3 rounded full-width">
                {{outputJson}}
            </output>
            <label class="label-calm">
                Publisher ID
                <input type="text" v-model="publisherId" class="form-control">
            </label>
            <output class="code-snippet p-3 rounded full-width">
                {{outputSql}}
            </output>
        </div>
        <datalist id="parsing-config-selectors">
            <option>meta[name='description']</option>
            <option>meta[property='og:description']</option>
        </datalist>
        <datalist id="parsing-config-attribute">
            <option>content</option>
            <option>alt</option>
            <option>value</option>
        </datalist>
    </div>
</template>

<script>
import ParsingConfigField from "./ParsingConfigField";
export default {
    name: "ParsingConfig",
    components: {ParsingConfigField},
    data() {
        return {
            title: null,
            summary: null,
            date: null,
            image: null,
            publisherId: ''
        }
    },
    computed: {
        outputData() {
            return {
                ...(this.title ? { title: this.formatField(this.title)} : {}),
                ...(this.summary ? { summary: this.formatField(this.summary)} : {}),
                ...(this.date ? { date: this.formatField(this.date)} : {}),
                ...(this.image ? { image: this.formatField(this.image)} : {}),
            }
        },
        outputJson() {
            return JSON.stringify(this.outputData, null, 4)
        },
        outputSql() {
            return `update publishers
set parsing_config = '${this.outputJson.replaceAll("'", "''")}'
where id = '${this.publisherId}'
            `
        }
    },
    methods: {
        formatField(field) {
            return {
                ...(field.selector ? {
                    element:{
                        selector: field.selector,
                        ...(field.attribute ? {attribute: field.attribute}:{}),
                    }
                } : {}),

                ...(field.favorSourceOverPage ? {
                    favor_source_over_page: field.favorSourceOverPage,
                } : {}),

                ...(field.hardcodedValue ? {
                    value: field.hardcodedValue,
                } : {}),

                ...(field.stripURLQueryParams ? {
                    strip_url_query_parameters: field.stripURLQueryParams,
                } : {}),

                ...(field.fairUse ? {
                    clipping:{
                        fair_use: field.fairUse,
                    }
                } : {}),
            }
        }
    }
}
</script>

<style scoped>
fieldset {
    margin: 0  1rem 1rem 0;
    width: 35%;
    flex-grow: 1;
}
.code-snippet {
    white-space: pre-wrap;
}
</style>

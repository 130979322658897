import {useReportData} from "@/composables/useReportData";
import {useOtherGrouping} from "@/composables/useOtherGrouping";
import {computed} from "vue";

export function usePieController({key, store, minPercent, maxCount, label, value, filters}) {
    const {data, load, listen, isLoading} = useReportData(key, store, filters);
    listen();
    load().then(() => console.log(`report:${key}:data`, data.value.map(x => value(x) || 0)));
    const otherGrouper = useOtherGrouping({
        minPercent,
        maxCount
    })
    const { getLabels, getData } = otherGrouper({
        labels: () => data.value.map(label),
        data: () => data.value.map(value)
    })
    const labels = computed(getLabels);
    const datasets = computed(() => [{data: getData()}]);
    return {labels, datasets, isLoading};
}

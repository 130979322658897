<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {defineProps, computed} from 'vue'
import BigNumber from "@/components/Controls/charts/BigNumber.vue";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const value = computed(() => data.value.map(x => x.count || 0)[0]);

const {data, load, listen, isLoading} = useReportData('account_summary', store, () => props.filters);

listen();
load().then(() => console.log('report:by_collection:data', data.value.map(x => x.count || 0)));
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <big-number v-else :value="value" unit="Clicks" ></big-number>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script setup>
import {computed, defineProps} from 'vue'
import PopoverButton from "@/components/Controls/PopoverButton.vue";
const props = defineProps({
    title: {type: String, default: 'Help'},
    content: String,
    html: { type: Boolean, default: false },
    helpDocLink: {type: String, default: null},
})
const content = computed(() => props.helpDocLink ? props.content + `<p>For more info <a href="${props.helpDocLink}" target="_blank">see docs</a></p>` : props.content)
</script>

<template>
    <popover-button class="btn btn-link me-0 pe-0 ms-0 ps-0 align-self-center" :html="props.html" :content="content">
        <i class="fa fa-question-circle font-size-18 align-bottom text-secondary"></i>
    </popover-button>
</template>

<style scoped>

</style>

<template>
    <v-select v-model="val" :options="options" label="name" @input="change" style="min-width: 300px">
        <template v-slot:option="option">
            <img class="sniply-image option" v-if="!!option.photo_url" :src="option.photo_url">
            <span class="sniply-option-text">
                <div>{{ option.name }}</div>
            </span>
        </template>
        <template v-slot:selected-option="option">
            <span class="selected-option">
                <img class="sniply-image" v-if="option.photo_url" :src="option.photo_url">
                {{ option.name }}
            </span>
        </template>
    </v-select>
</template>

<script>
import {VueSelect} from 'vue-select'
import {mapGetters} from 'vuex'
import {SniplyConnection} from "@/services/Sniply";

export default {
    name: "SelectSniplyBrand",
    components: {vSelect: VueSelect},
    props: {
        value: [String, Boolean]
    },
    async mounted() {
        this.loadBrandList();
    },
    computed: {
        options() {
            return this.brandsWithDummy();
        },
        Sniply() {
            return new SniplyConnection(this)
        },
        ...mapGetters({
            apis: 'externalApis',
            currentAccountOverview: 'currentAccountOverview'
        })
    },
    data() {
        return {
            brands: [],
            sniplyBrandsLoaded: false,
            val: false
        }
    },
    watch: {
        value() {
            this.val = this.brand();
        },
        brands() {
            this.val = this.brand();
        }
    },
    methods: {
        change(val) {
            this.$emit('input', val && val.id)
        },
        brand() {
            return this.brandsWithDummy().find(x => x.id === this.value) || false
        },
        brandsWithDummy() {
            return this.brands.some(x => x.id === this.value) ? this.brands :
                [...this.brands, {id: this.value, name: ' '}];
        },
        async loadBrandList(fetchNext) {
            if (!this.apis['sniply']) return Promise.resolve();
            if (fetchNext === undefined) this.brands = [];

            let fetchFirst = (() => this.Sniply.fetchBrands(this.currentAccountOverview));
            let brands = await (fetchNext || fetchFirst)();
            this.brands = this.$sort.alphabetize([...this.brands, ...brands.results]);

            if (brands.hasNext && this.brands.length < brands.count)
                await this.loadBrandList(brands.next);
            else {
                this.sniplyBrandsLoaded = true;
                this.$emit('loaded')
            }
        },
    }
}
</script>

<style scoped>
.option.sniply-image {
    max-height: 48px;
    max-width: 48px;
    vertical-align: top;
}

.sniply-option-text {
    display: inline-block;
}

.selected-option .sniply-image {
    max-height: 24px;
    max-width: 24px;
}

.selected-option {
    min-width: 200px;
    display: inline-block;
}
</style>

<script setup>
/* eslint-disable */
import store from '../../store';
import {computed, ref, watch} from "vue";
import moment from "moment";
import LabelerWithAddons from "@/components/Controls/LabelerWithAddons.vue";
import TinyToggle from "@/components/Controls/TinyToggle.vue";
import Combobox from "@/components/Controls/Combobox.vue";
import urlParamFilterHelp from '@/content/help/reports/url-param-filter.md'
import HelpButton from "@/components/Controls/HelpButton.vue";
import AutoCollapse from "@/components/Controls/AutoCollapse.vue";
import Dropdown from "@/components/Controls/Dropdown.vue";

const props = defineProps({
    collectionId: {type: String, required: false, default: ''},
    qpKey: {type: String, required: false, default: ''},
    qpValue: {type: String, required: false, default: ''},
    timespan: {type: String, required: false, default: 'month_to_date'},
    start: {type: String, required: false, default: null},
    end: {type: String, required: false, default: null},
    age: {type: Number, required: false, default: 90},
    groupBy: {type: String, required: false, default: 'week'},
})

const collectionId = ref(props.collectionId || '');
const timespan = ref(props.timespan || 'month_to_date');
const age = ref(props.age || 90);
const start = ref(props.start || moment(new Date()).add(-90, 'days').format('YYYY-MM-DD'));
const end = ref(props.end || moment(new Date()).format('YYYY-MM-DD'));
const qpKey = ref(props.qpKey || '');
const qpValue = ref(props.qpValue || '');
const groupBy = ref(props.groupBy || 'week')

const referrerMode = ref('Exclude');
const userAgentMode = ref('Exclude');
const urlParamNameMode = ref('Exclude');
const urlParamValueMode = ref('Exclude');

const referrers = ref([])
const userAgents = ref([])
const qpKeys = ref([])
const qpValues = ref([])

const rangeSpanDays = computed(() => moment(end.value).diff(moment(start.value), 'days'))

const defaultGroupBys = {
    month_to_date: () => 'day',
    last_month: () => 'day',
    year_to_date: () => 'month',
    last_year: () => 'month',
    week_to_date: () => 'day',
    last_week: () => 'day',
    age: () => age.value < 35 ? 'day' : age.value < 90 ? 'week' : 'month',
    date_range: () => rangeSpanDays.value < 35 ? 'day' : rangeSpanDays.value < 90 ? 'week' : 'month'
}

const localStartDisplay = computed(() => moment(start.value).format('l'));
const localEndDisplay = computed(() => moment(end.value).format('l'));

function setQueryParams(params) {
    const router = window.router;
    router.push({path: router.currentRoute.path, query: {...router.currentRoute.query, ...params}});
}

function refreshTime() {
    groupBy.value = defaultGroupBys[timespan.value]();
    refresh();
}
watch(rangeSpanDays, () => console.log('reports:rangeSpanDays', rangeSpanDays.value))

watch(collectionId, collectionId => {
    setQueryParams({collectionId});
})
watch(timespan, timespan => {
    setQueryParams({timespan});
})
watch(age, age => {
    setQueryParams({age});
})
watch(start, start => {
    setQueryParams({start});
})
watch(end, end => {
    setQueryParams({end});
})
watch(groupBy, end => {
    setQueryParams({groupBy});
})
watch(qpKey, qpKey => {
    setQueryParams({qpKey});
    qpValue.value = ''
})
watch(qpValue, qpValue => setQueryParams({qpValue}))

function getFilterValues() {
    return {
        collectionId: collectionId.value,
        timespan: timespan.value,
        start: timespan.value === 'date_range' && start.value ? moment(new Date(start.value)).format('YYYY-MM-DD') : undefined,
        end: timespan.value === 'date_range' && end.value ? moment(new Date(end.value)).format('YYYY-MM-DD') : undefined,
        qpKey: qpKey.value,
        qpValue: qpValue.value,
        groupBy: groupBy.value,
        age: timespan.value === 'age' ? age.value : undefined
    }
}

const filters = ref(getFilterValues())

function refresh() {
    if (JSON.stringify(filters.value) !== JSON.stringify(getFilterValues())) {
        filters.value = getFilterValues();
        emit('filters-changed', filters.value)
    }
}

const emit = defineEmits(['filters-changed']);
emit('filters-changed', filters.value)

const collections = store.getters.collections;

const features = computed(() => store.getters.accountFeatures);
const learnUrls = computed(() => store.getters.learnUrls);
</script>

<template>
    <div class="dashboard__header ms-4 me-4">
        <AutoCollapse label="Filters" id="reports-filters-collapse">
            <div class="d-flex gap-3 4 mt-3 mt-md-0 flex-wrap" id="reports-filters">
                <label class="d-block flex-grow-1 flex-lg-grow-0 form-label label-calm">
                    Collection
                    <select class="form-select" v-model="collectionId" @change="refresh">
                        <option value="">All collections</option>
                        <option v-for="a in collections" :key="a.id" :value="a.id">{{ a.name }}</option>
                    </select>
                </label>
                <label class="d-block flex-grow-1 flex-lg-grow-0 form-label label-calm">
                    Timespan
                    <select class="form-select" v-model="timespan" @change="refreshTime">
                        <option value="month_to_date">Month to date</option>
                        <option value="last_month">Last Month</option>
                        <option value="year_to_date">Year to date</option>
                        <option value="last_year">Last Year</option>
                        <option value="week_to_date">Week to date</option>
                        <option value="last_week">Last week</option>
                        <option value="age">Age in days</option>
                        <option value="date_range">Date range</option>
                    </select>
                </label>
                <label class="d-block flex-grow-1 flex-lg-grow-0 form-label label-calm" v-if="timespan === 'age'">
                    Age
                    <input type="number" class="form-control" v-model="age" step="1" min="0" max="366" @change="refreshTime">
                </label>
                <fieldset v-if="timespan === 'date_range'" class="flex-grow-1 flex-lg-grow-0">
                    <legend class="d-block  form-label label-calm mb-0" style="font-size: 1rem">Range</legend>
                    <dropdown class="dropdown mt-0">
                        <template #toggle>
                            <button class="btn btn-outline-secondary dropdown-toggle full-width" type="button" id="reports--utm-dropdown">
                                <span class="small">{{localStartDisplay}} - {{localEndDisplay}}</span>
                            </button>
                        </template>
                        <template #dropdown>
                            <div class="ms-3 me-3 mt-2 mb-2" aria-labelledby="reports--utm-dropdown">
                                <label class="d-block flex-grow-1 flex-lg-grow-0 form-label label-calm" v-if="timespan === 'date_range'">
                                    Start
                                    <input type="date" class="form-control" v-model="start">
                                </label>
                                <label class="d-block flex-grow-1 flex-lg-grow-0 form-label label-calm" v-if="timespan === 'date_range'">
                                    End
                                    <input type="date" class="form-control" v-model="end">
                                </label>
                                <button type="button" class="btn btn-primary full-width" @click="refreshTime">
                                    <i class="fa fa-refresh"/>
                                    Refresh
                                </button>
                            </div>
                        </template>
                    </dropdown>
                </fieldset>
                <label class="d-block flex-grow-1 flex-lg-grow-0 form-label label-calm">
                    Group By
                    <select class="form-select" v-model="groupBy" @change="refresh">
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                    </select>
                </label>
                <fieldset v-if="features.experimental" class="flex-grow-1 flex-lg-grow-0 d-print-none">
                    <legend class="d-block  form-label label-calm mb-0" style="font-size: 1rem">Advanced</legend>
                    <dropdown class="mt-0 full-width">
                        <template #toggle>
                            <button class="btn btn-outline-secondary dropdown-toggle full-width" type="button" id="reports--utm-dropdown">
                                <i class="fa fa-bars"/>
                            </button>
                        </template>
                        <template #dropdown>
                            <div class="ms-3 me-3 mt-2 mb-2" aria-labelledby="reports--utm-dropdown" id="reports--advanced-filters">
                                <div class="d-flex flex-wrap gap-3 mb-3">
                                    <LabelerWithAddons class="flex-grow-1">
                                        <template v-slot:label>
                                            URL Param Name
                                        </template>
                                        <template v-slot:label-right>
                                            <tiny-toggle v-model="urlParamNameMode" :options="['Include', 'Exclude', 'Values']"></tiny-toggle>
                                        </template>
                                        <!--                                    <input type="text" class="form-control" v-model="qpKey">-->
                                        <Combobox v-model="qpKeys" :multiple="true" :options="[]"/>
                                    </LabelerWithAddons>
                                    <LabelerWithAddons v-if="urlParamNameMode === 'Values' || qpValue" class="flex-grow-1">
                                        <template v-slot:label>
                                            URL Param Value
                                        </template>
                                        <template v-slot:label-right>
                                            <tiny-toggle v-model="urlParamValueMode" :options="['Include', 'Exclude']"></tiny-toggle>
                                        </template>
                                        <!--                                    <input type="text" class="form-control" v-model="qpValue" :disabled="!qpKey">-->
                                        <Combobox v-model="qpValues" :multiple="true" :options="[]" :disabled="qpKeys.length === 0"/>
                                    </LabelerWithAddons>
                                    <HelpButton html :content="urlParamFilterHelp" :help-doc-link="learnUrls.reports">
                                    </HelpButton>
                                </div>
                                <LabelerWithAddons class="mb-3">
                                    <template v-slot:label>
                                        Referrer
                                    </template>
                                    <template v-slot:label-right>
                                        <tiny-toggle v-model="referrerMode" :options="['Include', 'Exclude']"></tiny-toggle>
                                    </template>
                                    <Combobox v-model="referrers" :multiple="true" :options="[]"/>
                                </LabelerWithAddons>
                                <LabelerWithAddons class="mb-3">
                                    <template v-slot:label>
                                        User Agent
                                    </template>
                                    <template v-slot:label-right>
                                        <tiny-toggle v-model="userAgentMode" :options="['Include', 'Exclude']"></tiny-toggle>
                                    </template>
                                    <Combobox v-model="userAgents" :multiple="true" :options="[]"/>
                                </LabelerWithAddons>
                                <div class="alert alert-danger">
                                    These filters don't really work yet!
                                </div>
                                <button type="button" class="btn btn-primary full-width" @click="refresh">
                                    <i class="fa fa-refresh"/>
                                    Refresh
                                </button>
                            </div>
                        </template>
                    </dropdown>
                </fieldset>
            </div>
        </AutoCollapse>
    </div>
</template>

<style>
@media print {
    .dashboard .dashboard__header .collapse-button {
        display: none !important;
    }
    .dashboard .dashboard__header .collapse {
        display: block !important;
    }
    .dashboard .dashboard__header .dropdown-toggle {
        color: var(--dark);
        display: block !important;
        border: none !important;
        padding: 0 !important;
    }
    .dashboard .dashboard__header .dropdown-toggle::after {
        display: none !important;
    }
}
</style>
<style scoped>
@media print {
    select.form-select, input.form-control {
        border: none !important;
        padding: 0 !important;
        /*font-weight: bold;*/
        font-size: 1rem !important;
        color: var(--clay-0);
    }
    label {
        color: var(--clay-100);
        font-size: .8rem !important;
    }
}
#reports--advanced-filters {
    width: 600px;
    min-width: 300px;
}

@media screen and (max-width: 768px){
    #reports--advanced-filters {
        width: 400px;
        min-width: 300px;
    }
}
#reports-filters {
    gap: 0 1rem !important;
}
</style>

import * as types from '../mutation-types'

const state = {
    allTeamMembers: [],
    accountTeamMembers : [],
    accountRoles : {},
    currentUser : false
}

const getters = {
    teamMembers : state => state.allTeamMembers,
    accountTeamMembers : state => state.accountTeamMembers,
    currentRole : (state, getters) => {
        if (state.currentAccountOverview && state.currentUser){
            let users = state.accountRoles[getters.currentAccountOverview.id]
            if (users){
                return users[state.currentUser]
            }
        }
    }
}

const actions = {
    setRoleForUser : ({ commit }, { user }) => {
    commit('ADD_USER_ROLE', { user });
}
}

const mutations = {
    ADD_USER_ROLE : (state, {user}) => {
        if ( ! state.accountRoles[user.account]){
            state.accountRoles[user.account] = {};
        }
        state.accountRoles[user.account][user.id] =  user.role;
    },
    [types.USER_DID_LOG_IN](state, {user}){
        state.currentUser = user.id;
    },
    [types.DELETE_TEAM_MEMBER](state, {teamMember}){
        state.teamMembers = state.teamMembers.filter(t => t.id !== teamMember.id); 
    },
    [types.ADD_TEAM_MEMBER_TO_ACCOUNT](state, {user, account}){
        let existingAccount = state.accountTeamMembers.find(accnt => accnt.id === account.id);
        if ( ! existingAccount){
            existingAccount = {id : account.id, members : []}
        }
        let index = state.accountTeamMembers.indexOf(existingAccount)

        let existingUser = existingAccount.members.find(u => u.id === user.id);

        if (  ! existingUser){
            existingAccount.members.push(user);
        }

        if (index > -1){
            state.accountTeamMembers.splice(index, 1, existingAccount)
        } else {
            state.accountTeamMembers.push(existingAccount)
        }

        if ( ! state.accountRoles[user.account]) {
            state.accountRoles[user.account] = {};
        }
        state.accountRoles[user.account][user.id] = user.role;

    },
    [types.ADD_TEAM_MEMBER](state, { user }){
        if ( ! state.allTeamMembers.find(tm => {
            return tm.id === user.id
        })){
            state.allTeamMembers.push(user)
        }
    },
    [types.USER_DID_LOG_OUT](state){
        state.allTeamMembers = [];
        state.accountTeamMembers = [];
        state.accountRoles = {}
        state.currentUser = false;
    },
    [types.REMOVE_TEAM_MEMBER_FROM_ACCOUNT](state, {user, account}){
        const existingAccount = state.accountTeamMembers.find(accnt => accnt.id === account.id);
        if (!existingAccount) return;
        
        let index = state.accountTeamMembers.indexOf(existingAccount)
        const existingUser = existingAccount.members.find(u => u.id === user.id);

        if ( ! existingUser) return

        let userIndex = existingAccount.members.indexOf(existingUser);
        existingAccount.members.splice(userIndex, 1);

        state.accountTeamMembers.splice(index, 1, existingAccount)
    },
    [types.UPDATE_TEAM_MEMBER_IN_ACCOUNT](state, {user, account}){
        const existingAccount = state.accountTeamMembers.find(accnt => accnt.id === account.id);
        if (!existingAccount) return;

        let index = state.accountTeamMembers.indexOf(existingAccount)
        const existingUser = existingAccount.members.find(u => u.id === user.id);

        if (!existingUser) return

        let userIndex = existingAccount.members.indexOf(existingUser);
        existingAccount.members.splice(userIndex, 1, user);

        state.accountTeamMembers.splice(index, 1, existingAccount)
    },
     [types.UPDATE_USER_PROFILE](state, {payload}){
        let key = payload.key;
        let value = payload.value
        const user = {...payload.user}
        user[key] = value;

        const existingAccount = state.accountTeamMembers.find(accnt => accnt.id === payload.account.id);
        if (!existingAccount) return;

        let index = state.accountTeamMembers.indexOf(existingAccount)
        const existingUser = existingAccount.members.find(u => u.id === user.id);

        if (!existingUser) return

        let userIndex = existingAccount.members.indexOf(existingUser);
        existingAccount.members.splice(userIndex, 1, user);

        state.accountTeamMembers.splice(index, 1, existingAccount)
        state.allTeamMembers.splice( state.allTeamMembers.indexOf(state.allTeamMembers.find(u => u.id === user.id)), 1, user );
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

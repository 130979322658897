import {ref, Ref, watch, Prop} from 'vue';
import {Store} from 'vuex'
import {Apis} from '@/api';

export function useReportData(key: string|(() => string), store: Store<any>,  filters: () => {collectionId, timespan, age, start, end, qpKey, qpValue}) {
    const data = ref([]);
    const isLoading = ref(false);
    const apis: () => Apis = () => window['$app'].apis;

    const getKey = typeof key === 'string' ? () => key : key;
    async function fetch() {
        const accountId = store.getters.currentAccountOverview.id;
        if (!accountId || !filters()) return null;
        return await apis().reports.accountsAccountIdReportsKeyDataGet(accountId, getKey(),
            filters().timespan,
            filters().collectionId || undefined,
            filters().start || undefined, filters().end || undefined,
            filters().age || undefined,
            filters().qpKey || undefined, filters().qpValue || undefined
        )
    }
    async function load() {
        isLoading.value = true;
        const result = await fetch();
        console.log('report:result', result);
        data.value = result?.data || [];
        isLoading.value = false;
    }
    function listen() {
        watch(() => store.getters.currentAccountOverview, load)
        watch(() => filters(), load)
        watch(getKey, load)
    }
    return {
        data, fetch, load, listen, isLoading
    }
}

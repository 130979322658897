<template>
    <div>
        <div
            class="topic-item selectable-item"
            v-for="collection in pagedCollections"
            :key="'collection-'+collection.id"
            :class="{'active text-primary selected-item' :
            hasSuggestions.type === 'collection' && currentCollection.id === collection.id,
            checkable: isCheckable,
            disabled: isCheckable && type === 'collections'}"
        >
            <label v-if="isCheckable">
                <input type="checkbox"
                       v-model="checkedCollections"
                       :value="collection.id"
                       :disabled="currentCollection.id === collection.id && type === 'collections'"/>&nbsp;
                <search-highlighter :text="collection.name" :search-text="search"></search-highlighter>
            </label>
            <div @click="setCurrentCollection(collection)" v-else>
                <span class="icon-width me-1"><i :class="icon(collection)"></i></span>
                <search-highlighter :text="collection.name" :search-text="search"></search-highlighter>
            </div>
        </div>

        <paginator :items="searchedCollections"
                   @update="pagedCollections = $event"
                   :size="10"
                   :extra-small="true"
                   :stay-on-first-page="true"/>
    </div>
</template>

<script>

import Paginator from '@/utilities/Paginator'
import {mapGetters} from 'vuex';
import {highlightSearchText} from '@/services/SearchHighlighter'
import {getIconClass} from "@/services/Icons";
import SearchHighlighter from "../Controls/SearchHighlighter";

export default {
    props: ['collections', 'isCheckable', 'value', 'search'],
    components: {
        SearchHighlighter,
        Paginator
    },
    data() {
        return {
            pagedCollections: [],
            checkedCollections: this.value ?
                this.value.filter(checked => !!this.collections && this.collections.some(c => c.id == checked)) : []
        }
    },
    watch: {
        checkedCollections() {
            let checkedForOtherFolders = this.value ?
                this.value.filter(checked => !!this.collections && this.collections.every(c => c.id !== checked)) : [];
            this.$emit('input', [...this.checkedCollections, ...checkedForOtherFolders]);
        }
    },
    computed: {
        ...mapGetters({
            currentCollection: 'currentCollection',
            type: 'suggestionViewerType',
            hasSuggestions: 'hasSuggestions',
        }),

        searchedCollections() {
            if (this.search) {
                let search = c => c.name.toLowerCase().includes(this.search.toLowerCase());
                return this.collections.filter(search);
            }
            return this.collections;
        },
    },
    methods: {
        setCurrentCollection(collection) {
            console.log('* PagedCollectionInFolder setCurrentCollection')
            this.$store.dispatch('setCurrentCollection', {collection});
            if (this.$router.currentRoute.path !== '/') {
                console.log('* PagedCollectionInFolder setCurrentCollection about to /')
                this.$router.push('/');
            }
            window.Events.$emit('NavigatedToSuggestion');
        },
        highlight(item) {
            return highlightSearchText(item, this.search)
        },
        isAmplify(collection) {
            collection;
        },
        icon(collection) {
            return getIconClass(collection.icon || 'bolt')
        }
    }
}
</script>

<style scoped>
.selectable-item {
    padding: 0 5px;
}

.selected-item {
    font-weight: var(--font-weight-semibold);
    background-color: var(--bs-primary);
    color: var(--bs-light) !important;
    border-radius: var(--border-radius);
}

.selected-item.checkable {
    font-weight: normal;
    background-color: unset;
    color: unset !important;
    margin: 0;
}


.selected-item.disabled {
    text-decoration: line-through;
    color: var(--secondary) !important;
}

.icon-width {
    width: 1em;
    display: inline-block;
    text-align: center;
}
</style>

<script setup lang="ts">
import {defineProps} from 'vue';
import {randomId} from "@/utilities/Id";
const props = defineProps({
    label: {type: String, required: true},
})
const id = randomId();
const contentId = 'collapse' + id + '-contents'
</script>

<template>
    <div class="position-relative">
        <a class="btn btn-secondary btn-sm full-width d-block d-md-none collapse-button"
           :href="'#'+contentId"
           data-bs-toggle="collapse"
           role="button" aria-expanded="false" :aria-controls="contentId">
            {{props.label}}
            <span class="dropdown-toggle"></span>
        </a>
        <div class="collapse d-flex flex-wrap" :id="contentId">
           <slot/>
        </div>
    </div>
</template>

<style scoped>


.collapse {
}
.collapse.d-flex {
    display: none !important;
}
.collapse.show.d-flex {
    display: flex !important;
}
@media screen and (min-width: 768px) {
    .collapse.d-flex {
        display: flex !important;
    }
}
</style>

<script setup>
/* eslint-disable */
import store from '../store';
import {computed, ref, watch} from "vue";
import ClickHistory from "@/components/charts/ClickHistory.vue";
import ClicksByCountry from "@/components/charts/ClicksByCountry.vue";
import DashboardContainer from "@/components/Controls/charts/DashboardContainer.vue";
import ClicksByCollection from "@/components/charts/ClicksByCollection.vue";
import TopArticles from "@/components/charts/TopArticles.vue";
import TotalClicks from "@/components/charts/TotalClicks.vue";
import TopDomains from "@/components/charts/TopDomains.vue";
import ByUrlParam from "@/components/charts/ByUrlParam.vue";
import ArticleSummary from "@/components/charts/ArticleSummary.vue";
import ReportFilters from "@/components/Reporting/ReportFilters.vue";
import ReportHeader from "@/components/Reporting/ReportHeader.vue";

const props = defineProps({
    collectionId: {type: String, required: false, default: ''},
    qpKey: {type: String, required: false, default: ''},
    qpValue: {type: String, required: false, default: ''},
    timespan: {type: String, required: false, default: 'last_month'},
    start: {type: String, required: false, default: null},
    end: {type: String, required: false, default: null},
    age: {type: Number, required: false, default: 90},
})

console.log('query props', props)

const colors = ['purple', 'violet', 'blue', 'cyan', 'green', 'yellow', 'orange', 'red']


const filters = ref(null)

const features = computed(() => store.getters.accountFeatures);
</script>

<template>
    <div class="dashboard">
        <ReportHeader class="mt-2 ms-4 me-4 mb-2" />
        <ReportFilters @filters-changed="x => filters = x" v-bind="props"/>
        <div class="m-4">
            <div class="dashboard__body row">
                <dashboard-container :span="1" label="Total Account Clicks">
                    <TotalClicks :filters="filters" />
                </dashboard-container>
                <dashboard-container :span="1" label="Articles">
                    <ArticleSummary :filters="filters" />
                </dashboard-container>
                <dashboard-container :span="2" label="By Country">
                    <ClicksByCountry :filters="filters" class="pie-chart" />
                </dashboard-container>
                <dashboard-container :span="2" label="By Collection">
                    <ClicksByCollection :filters="filters"/>
                </dashboard-container>
                <dashboard-container :span=2 label="Click History">
                    <ClickHistory :filters="filters" class="bar-chart" :by="filters && filters.groupBy || props.groupBy"/>
                </dashboard-container>
                <dashboard-container :span="4" label="Top Articles" expand>
                    <TopArticles :filters="filters"/>
                </dashboard-container>
                <dashboard-container :span="4" label="Top Domains" expand>
                    <TopDomains :filters="filters"/>
                </dashboard-container>
                <dashboard-container :span="4" label="URL Parameters" expand>
                    <ByUrlParam :filters="filters"/>
                </dashboard-container>
<!--                <dashboard-container :span="1" label="Medium Numbers">-->
<!--                    <big-numbers :values="[{value: 42, unit: 'flops', label: 'Happiness'}, {value: 1337, unit: 'codes', label: 'Leetness'}]"></big-numbers>-->
<!--                </dashboard-container>-->
<!--                <dashboard-container :span="1" label="Small Numbers">-->
<!--                    <numbers :values="[{value: 42, unit: 'flops', label: 'Happiness'}, {value: 1337, unit: 'codes', label: 'Leetness'}, {value: 1337, unit: 'codes', label: 'Leetness'}, {value: 1337, unit: 'codes', label: 'Leetness'}]"></numbers>-->
<!--                </dashboard-container>-->

            </div>
        </div>
    </div>
</template>
<style scoped>
</style>

